import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer
} from 'grommet';
import _ from 'lodash';
import { Close, Checkmark } from 'grommet-icons';
import { redeemTicket } from '../store/TicketedEvent'
import { siteSlice } from '../store/Site';
import QrCodeScanner from '../components/QrCodeScanner';
import parse from 'html-react-parser';

const mapDispatchToProps = {
    redeemTicket,
    ...siteSlice.actions
}

interface IRedeemTicketProps {
}

interface RedeemTicketState {
    checked: boolean;
    scanning: boolean;
    qrCode: string;
}

type RedeemTicketProps = IRedeemTicketProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class RedeemTicket extends React.PureComponent<RedeemTicketProps, RedeemTicketState> {
    canvasRef: any;
    videoRef: any;

    constructor(props: RedeemTicketProps) {
        super(props);

        this.state = {
            checked: false,
            scanning: false,
            qrCode: ''
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("events-menu")
        this.canvasRef = React.createRef();
        this.videoRef = React.createRef();
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSuccess = () => {
        this.setState({ checked: true })
    }

    onFailure = () => {
        this.setState({ checked: true })
    }

    onReadCode = () => {
        this.setState({ scanning: true, checked: false })
    }

    codeCaptured = (decodedText: any, decodedResult: any) => {
        this.setState({ scanning: false, qrCode: decodedText });
        this.props.redeemTicket(decodedText, this.onSuccess, this.onFailure)
    }

    public render() {
        let ticket = this.props.ticketedEvent.ticket;

        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="small" >
            {this.state.checked && ticket && <Box gap='medium' align='center'>
                <Box align='center' justify='center' background={{ color: 'green' }} height="small" width="small">
                    <Checkmark size='xlarge' color='white' />
                </Box>
                <Box gap="small" fill>
                    {parse(ticket.description)}
                </Box>
            </Box>
            }
            {this.state.scanning && <Box gap='small'>
                <Box width="medium" height="medium">
                    <QrCodeScanner fps={10} disableFlip={false} successCallback={this.codeCaptured} />
                </Box>
                <Button secondary label="Cancel" color="brand" onClick={() => { this.setState({ scanning: false }) }} />
            </Box>}
            {!this.state.scanning && <Button primary label="Redeem Ticket" color="brand" onClick={this.onReadCode} />}
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(RedeemTicket as any);
