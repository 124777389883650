import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer, Tip
} from 'grommet';
import { FormClose, FormEdit, FormView, Send } from 'grommet-icons';
import _ from 'lodash';
import { Close, Checkmark } from 'grommet-icons';
import { retrieveAttendees, sendConfirmationEmail, checkInAttendee, schoolEventSlice } from '../store/SchoolEvent'
import { siteSlice } from '../store/Site';
import { formatISO, parseJSON, format as formatDate } from 'date-fns'
import { EventAttendee } from '../models/EventAttendee';
import ViewRegistration from '../components/ViewRegistration';
import { firstBy } from 'thenby';

const mapDispatchToProps = {
    retrieveAttendees,
    sendConfirmationEmail,
    checkInAttendee,
    ...schoolEventSlice,
    ...siteSlice.actions
}

interface IAttendeesProps {
}

interface AttendeesState {
    attendee: EventAttendee | undefined;
    showRegistration: boolean;
}

type AttendeesProps = IAttendeesProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Attendees extends React.PureComponent<AttendeesProps, AttendeesState> {
    constructor(props: AttendeesProps) {
        super(props);

        this.state = {
            attendee: undefined,
            showRegistration: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("events-menu")
        this.props.retrieveAttendees(props.match.params.eventId);
    }

    public componentDidMount() {
    }

    onViewAttendee = (attendee: EventAttendee) => {
        this.setState({ attendee: attendee, showRegistration: true });
    }

    onCloseAttendee = () => {
        this.setState({ attendee: undefined, showRegistration: false });
    }

    onCheckIn = (attendee: EventAttendee) => {
        this.props.checkInAttendee(attendee);
    }

    rentderAttendees = () => {
        let currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        let attendees = this.props.events.attendees.sort(firstBy('name'));
        let eventCost = attendees.length !== 0 ? attendees[0].event.cost : 0;
        let rows = attendees.map((attendee, index) => {
            let total = eventCost + eventCost * (attendee.additionalAttendees ? attendee.additionalAttendees.length : 0) +
                eventCost * (attendee.additionalAthletes ? attendee.additionalAthletes.length : 0);
            let numOfAttendees = (attendee.additionalAttendees ? attendee.additionalAttendees.length : 0) +
                (attendee.additionalAthletes ? attendee.additionalAthletes.length : 0);

            return <TableRow key={`attendee-${index}`}>
                <TableCell>{attendee.name}</TableCell>
                <TableCell>{currency.format(total)}</TableCell>
                <TableCell>{numOfAttendees}</TableCell>
                <TableCell>{(attendee.checkInDate ? formatDate(parseJSON(attendee.checkInDate), 'yyyy-MM-dd hh:mm:ss') : '')}</TableCell>
                <TableCell>
                    <Box direction='row-responsive' gap="small">
                        <Tip content="Check in">
                            <Button icon={<Checkmark />} onClick={() => { this.onCheckIn(attendee); }} />
                        </Tip>
                        <Tip content="View registration">
                            <Button icon={<FormView />} onClick={() => { this.onViewAttendee(attendee); }} />
                        </Tip>
                        <Tip content="Send confirmation email">
                            <Button icon={<Send />} onClick={() => { this.props.sendConfirmationEmail(attendee); }} />
                        </Tip>
                    </Box>
                </TableCell>
            </TableRow>;
        });

        return rows;
    }

    public render() {
        let attendees = this.props.events.attendees;
        let event = attendees.length !== 0 ? attendees[0].event : undefined;
        let total = _.sum(attendees.map((attendee, index) => {
            return 1 + (attendee.additionalAttendees ? attendee.additionalAttendees.length : 0) +
                (attendee.additionalAthletes ? attendee.additionalAthletes.length : 0);
        }));
        return <Box align='center' pad="small" fill overflow="auto" gap="small" >
            {this.state.showRegistration && <Layer>
                <ViewRegistration {...this.props} data={this.state.attendee as EventAttendee} onClose={this.onCloseAttendee} />
            </Layer>}
            {event && <Heading fill level={2}>{`${event.displayName} Attendees`}</Heading>}
            <Table className='striped'>
                <TableHeader>
                    <TableRow>
                        <TableCell>Registrant</TableCell>
                        <TableCell>Amount Paid</TableCell>
                        <TableCell>Additional Attendees</TableCell>
                        <TableCell>Checkin Date</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {this.rentderAttendees()}
                </TableBody>
            </Table>
            <Box align='end' fill="horizontal" pad="small">
                <Text weight="bold">Total Attendees: {total}</Text>
            </Box>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Attendees as any);
