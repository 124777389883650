import React from 'react';
import { Box } from 'grommet';
import { Html5QrcodeScannerState, Html5Qrcode } from "html5-qrcode";
import { QrDimensions } from 'html5-qrcode/esm/core';

const qrcodeRegionId = "html5qr-code-full-region";

interface QrCodeScannerProps {
    fps: number | undefined;
    qrbox?: number | QrDimensions | undefined;
    aspectRatio?: number | undefined;
    disableFlip?: boolean | undefined;
    videoConstraints?: MediaTrackConstraints | undefined;
    verbose?: boolean;
    successCallback: Function;
    errorCallback?: Function;
}

interface QrCodeScannerState {
}

export default class QrCodeScanner extends React.PureComponent<QrCodeScannerProps, QrCodeScannerState> {
    html5QrcodeScanner: any;

    componentWillUnmount() {
        let cameraState = this.html5QrcodeScanner.getState();

        if (cameraState !== Html5QrcodeScannerState.NOT_STARTED) {
            this.html5QrcodeScanner.stop().then((ignore: any) => {
                // QR Code scanning is stopped.
            }).catch((err: any) => {
                // Stop failed, handle it.
            });
        }
    }

    createConfig = (props: any) => {
        var config: any = {};

        if (props.fps) {
            config.fps = props.fps;
        }
        if (props.qrbox) {
            config.qrbox = props.qrbox;
        }
        if (props.aspectRatio) {
            config.aspectRatio = props.aspectRatio;
        }
        if (props.disableFlip !== undefined) {
            config.disableFlip = props.disableFlip;
        }
        return config;
    }

    onSuccess = (decodedText: any, decodedResult: any) => {
        this.html5QrcodeScanner.stop().then((ignore: any) => {
            // QR Code scanning is stopped.
        }).catch((err: any) => {
            // Stop failed, handle it.
        });
        this.props.successCallback(decodedText, decodedResult);
    }

    componentDidMount() {
        // Creates the configuration object for Html5QrcodeScanner.
        var config = this.createConfig(this.props);
        var verbose = this.props.verbose === true;

        // Suceess callback is required.
        if (!(this.props.successCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        this.html5QrcodeScanner = new Html5Qrcode(qrcodeRegionId, verbose);
        this.html5QrcodeScanner.start({ facingMode: "environment" }, config, this.onSuccess);
        // this.html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        // this.html5QrcodeScanner.render(this.props.successCallback, this.props.errorCallback);
    }

    render() {
        return <Box id={qrcodeRegionId} />;
    }

};
