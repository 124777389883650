import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { EventTicket } from '../models/EventTicket';
import { TicketedEvent } from '../models/TicketedEvent';
import { setLoading, setError, setMessage } from './Site';
import { EmptyGuid } from '../models/Common'

let serialize = require('serialize-javascript');

export interface TicketedEventState {
    event?: TicketedEvent;
    ticket?: EventTicket;
}

let initialState: TicketedEventState = {
}

export const ticketedEventSlice = createSlice({
    name: 'TicketedEvent',
    initialState,
    reducers: {
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setTicket: (state, action) => {
            state.ticket = action.payload;
        }
    }
});

export const { setEvent, setTicket } = ticketedEventSlice.actions;

export const retrieveEvent = (event: string): AppThunk => async (dispatch) => {
    let request = {
        name: event
    }

    fetch('api/ticket/retrieveEvent', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setEvent(data));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const saveEvent = (event: TicketedEvent): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    fetch('api/ticket/saveEvent', {
        method: "POST",
        body: serialize(event),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setEvent(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const redeemTicket = (ticketId: string, onSuccess: Function, onFailure: Function): AppThunk => async (dispatch) => {
    let request = {
        id: ticketId,
    }

    dispatch(setLoading(true));
    fetch('api/ticket/redeemTicket', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        if (data) {
            dispatch(setTicket(data));
            onSuccess();
        }
        else {
            onFailure();
        }
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const saveTicket = (ticket: any): AppThunk => async (dispatch) => {
    let request = {
        ...ticket
    }

    dispatch(setLoading(true));
    fetch('api/ticket/saveTicket', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        if (!data.id || (data.id === EmptyGuid)) {
            setMessage("An error occurred saving ticket information. Please send the email that was composed.");
            window.open(`mailto:support@mrhsabc.com&subject=Issue saving ticket information&body=${JSON.stringify(request)}%0d%0a%0d%0a${JSON.stringify(data)}`);
        }
        else {
            dispatch(setTicket(data));
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const sendConfirmationEmail = (ticket: EventTicket): AppThunk => async (dispatch) => {

    dispatch(setLoading(true));
    fetch('api/ticket/sendConfirmationEmail', {
        method: "POST",
        body: serialize(ticket),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        setMessage("Confirmation email has been sent.");
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}


export default ticketedEventSlice.reducer;
