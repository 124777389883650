export interface KeyValuePair {
    key: string;
    value: any;
}

export interface SelectOption {
    selected: boolean;
    text: string;
    value: string;
}

export interface TypedValue {
    type: string;
    value: string;
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export const Sports = ["Basketball", "Baseball", "Football", "Volleyball", "Cheer", "Cross Country", "Golf", "Soccer",
    "Softball", "Swimming", "Tennis", "Track & Field", "Wrestling"];
