import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { registerMember, membershipSlice } from '../../store/Membership';
import { Box, Heading, Button, Layer, Header } from 'grommet';
import { Athlete as AthleteModel } from '../../models/Athlete';
import { Member } from '../../models/Member';
import { Close } from 'grommet-icons';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { TypedValue as TypedValueModel } from '../../models/Common';
import Membership from '../../components/Membership';

const mapDispatchToProps = {
    registerMember,
    ...siteSlice.actions,
    ...membershipSlice.actions
}

interface MembershipJoinState {
    member: Member | undefined;
    athlete: AthleteModel | undefined;
    typedValue: TypedValueModel | undefined;
    addAthlete: boolean;
    submitPayment: boolean;
    addTypedValue: boolean;
    typeLabel: string | undefined;
    typeOptions: string[];
    callback: Function | undefined;
    levels: any[];
}

type MembershipJoinProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class MembershipJoin extends React.PureComponent<MembershipJoinProps, MembershipJoinState> {
    constructor(props: MembershipJoinProps) {
        super(props);

        this.state = {
            member: undefined,
            athlete: undefined,
            typedValue: undefined,
            addAthlete: false,
            submitPayment: false,
            addTypedValue: false,
            callback: undefined,
            typeLabel: undefined,
            typeOptions: [],
            levels: [
                {
                    name: "Faculty/Staff",
                    cost: 10,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Faculty/Staff Lifetime",
                    cost: 50,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Varsity",
                    cost: 25,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Purple Out",
                    cost: 100,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)"
                    ]
                },
                {
                    name: "Maverick Spirit",
                    cost: 200,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)",
                        "1/4 Page Ad in Sports Program"
                    ]
                },
                {
                    name: "Maverick Super Star",
                    cost: 350,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)",
                        "1/4 Page Ad in Sports Program",
                        "2 - KISD Athletic Passes"
                    ]
                }
            ]
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("membership-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSubmit = (value: Member) => {
        this.setState({ member: value, submitPayment: true })
    }

    onCancelPayment = () => {
        this.setState({ submitPayment: false })
    }

    onCancel = () => {
        this.setState({ member: undefined });
    }

    createOrder = (_data: any, actions: any) => {
        let member = { ...this.state.member } as Member;
        let level = this.state.levels.find(lvl => lvl.name == member?.level);
        let currentDate = new Date();
        let month = currentDate.getMonth();
        let year = currentDate.getFullYear();
        let schoolYear = month >= 6 ? year : year - 1;
        let description = `${schoolYear} - ${schoolYear + 1} Morton Ranch High School Athletic Booster Club Membership`;

        return actions.order.create({
            purchase_units: [
                {
                    description: description,
                    amount: {
                        currency_code: "USD",
                        value: level.cost
                    }
                }
            ]
        });
    }

    onOrderApproved = (_data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
            let member = { ...this.state.member } as Member;

            member.paymentType = "PayPal";
            member.paymentId = details.id;
            this.props.registerMember(member, this.onMemberRegistered);
        });
    }

    onPaymentError = (err: any) => {
        this.props.setError(err.message);
    }

    onMemberRegistered = () => {
        this.props.setMessage("Your membership has been received");
    }

    public render() {

        return <Box align='center' fill overflow="auto" gap="small" pad="small">
            <Heading fill textAlign='center'>Membership Application</Heading>
            <Membership member={this.state.member} onSubmit={this.onSubmit} {...this.props} />
            {this.state.submitPayment && <Layer>
                <Box width="medium" height={{ max: "large" }} gap="small" overflow={{ vertical: "auto" }}>
                    <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                        <Heading level="4" fill textAlign='center'>Submit Payment</Heading>
                        <Box align="end" justify="start">
                            <Button type="button" icon={<Close size="small" />} onClick={this.onCancelPayment} />
                        </Box>
                    </Header>
                    <Box pad="small" gap="small">
                        <PayPalButtons onCancel={this.onCancelPayment} createOrder={this.createOrder}
                            onApprove={this.onOrderApproved} onError={this.onPaymentError} />
                    </Box>
                </Box>
            </Layer>}
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MembershipJoin as any);
