import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { Member } from '../models/Member';
import { setLoading, setError } from './Site';

let serialize = require('serialize-javascript');

export interface MembershipState {
    member: Member | undefined;
    members: Member[];
}

let initialState: MembershipState = {
    member: undefined,
    members: []
}

export const membershipSlice = createSlice({
    name: 'Membership',
    initialState,
    reducers: {
        setMember: (state: MembershipState, action: any) => {
            state.member = action.payload;
        },
        setMembers: (state: MembershipState, action: any) => {
            state.members = action.payload;
        }
    }
});

export const { setMember, setMembers } = membershipSlice.actions;

export const registerMember = (member: Member, callback?: Function): AppThunk => async (dispatch) => {
    let request = serialize(member);

    dispatch(setLoading(true));
    fetch('api/membership/register', {
        method: "POST",
        body: request,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setMember(data));
        dispatch(setLoading(false));
        if(callback){
            callback();
        }

    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const retrieveMembers = (): AppThunk => async (dispatch) => {
    let request = serialize({});

    dispatch(setLoading(true));
    fetch('api/membership/list', {
        method: "POST",
        body: request,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setMembers(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export default membershipSlice.reducer;
