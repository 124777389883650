import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer, Card, CardHeader, CardBody
} from 'grommet';
import _ from 'lodash';
import { siteSlice } from '../../store/Site';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface IBasketballProps {
}

interface BasketballState {
    checked: boolean;
}

type BasketballProps = IBasketballProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Basketball extends React.PureComponent<BasketballProps, BasketballState> {
    constructor(props: BasketballProps) {
        super(props);

        this.state = {
            checked: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("sports-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="small" >
            <Box height="large" fill="horizontal" overflow="auto">
                <iframe width="100%" height="100%" frameBorder="0"
                    src='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=17&Tm=14296&L=1&Mt=0' />
            </Box>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Basketball as any);
