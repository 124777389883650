import { ThunkAction, Action } from '@reduxjs/toolkit';
import membershipReducer, { MembershipState } from './Membership';
import siteReducer, { SiteState } from './Site';
import schoolEventReducer, {  SchoolEventState } from './SchoolEvent';
import socialMediaReducer, {  SocialMediaState } from './SocialMedia';
import ticketedEventReducer, {  TicketedEventState } from './TicketedEvent';

// The top-level state object
export interface ApplicationState {
    site: SiteState;
    membership: MembershipState;
    events: SchoolEventState;
    socialMedia: SocialMediaState;
    ticketedEvent: TicketedEventState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    site: siteReducer,
    membership: membershipReducer,
    events: schoolEventReducer,
    socialMedia: socialMediaReducer,
    ticketedEvent: ticketedEventReducer
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action<string>>;

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
