import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from '.';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session';

export default function setupStore(history: History, initialState?: ApplicationState) {

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const persistConfig = {
        key: 'root',
        version: 1,
        storage: storageSession
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer);


    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => {
            let middleware = getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                }
            });

            middleware.push(routerMiddleware(history));
            if (process.env.NODE_ENV !== 'production') {
                middleware.push(logger);
            }
        
            return middleware;
        },
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production'
    })
}
