import React from 'react';
import { Box, Form, FormField, TextInput, Button, Select, Heading, Header, Footer } from 'grommet';
import { Close } from 'grommet-icons';
import _ from 'lodash';
import { TypedValue as TypedValueModel } from '../models/Common';
import { ApplicationState } from '../store';

interface ITypedValueProps {
    data?: TypedValueModel;
    label?: string;
    options: string[];
    callback: Function;
    onClose?: any;
    type?: string;
}

interface TypedValueState {
    typedValue: TypedValueModel | undefined;
    showSelect: boolean;
}


type TypedValueProps =
    ITypedValueProps // ... state we've requested from the Redux store
    & ApplicationState; // ... state we've requested from the Redux store

export default class TypedValue extends React.PureComponent<TypedValueProps, TypedValueState> {
    constructor(props: TypedValueProps) {
        super(props);

        this.state = {
            typedValue: this.props.data,
            showSelect: false
        };
    }

    onAddTypedValue = (value: TypedValueModel) => {
        this.props.callback(value);
    }

    canSave = () => {
        let typedValue = this.state.typedValue;
        let result = typedValue?.type && typedValue.value;

        return result;
    }

    public render() {
        return <Box>
            <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                <Heading level="4" fill>Add {this.props.label ?? "Typed Value"}</Heading>
                <Box align="end" justify="start">
                    <Button type="button" icon={<Close size="small" />} onClick={this.props.onClose} />
                </Box>
            </Header>
            <Box pad="small" gap="medium" height={{ max: "medium" }} overflow="auto" border>
                <Form value={this.state.typedValue} onChange={nextValue => this.setState({ typedValue: nextValue })}
                    onSubmit={({ value }) => { this.onAddTypedValue(value as TypedValueModel) }}>
                    <FormField name="type" htmlFor="type-input" label="Type" required >
                        <Select options={this.props.options} name="type" />
                    </FormField>
                    <FormField name="value" htmlFor="value-input" label={this.props.label ?? "Value"} required >
                        <TextInput type={`${this.props.type ?? "text"}`} id="value-input" name="value" />
                    </FormField>
                    <Footer direction="row" gap="small" pad="small" justify="end">
                        <Button type="submit" primary color="brand" label="Save" disabled={!this.canSave()} />
                        <Button secondary label="Cancel" onClick={this.props.onClose} color="brand" />
                    </Footer>
                </Form>
            </Box>
        </Box>
    }
}
