import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { Box, Image, Text, Button, Anchor, Card, CardHeader, CardBody, Tabs, Tab } from 'grommet';
import { Ticket, Link, Schedule } from 'grommet-icons';
import { siteSlice } from '../../store/Site';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface IFootballProps {
}

interface FootballState {
    checked: boolean;
}

type FootballProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Football extends React.PureComponent<FootballProps, FootballState> {
    constructor(props: FootballProps) {
        super(props);

        this.state = {
            checked: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("sports-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="small" >
            <Box direction='row-responsive' gap='medium' align='center' justify='center' fill >
                <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "450px" }}>
                    <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small'><Link size='small' /><Text weight={"bold"}>Useful Links</Text></CardHeader>
                    <CardBody gap='small' pad="small">
                        <Anchor icon={<Ticket size='large' />} target='_blank' label={<Text weight='bold'>Buy Season Tickets</Text>}
                            href='https://www.etix.com/ticket/v/11016/academy-sports-outdoors-student-activities-complex' />
                        <Anchor icon={<Ticket size='large' />} target='_blank' label={<Text weight='bold'>Buy Game Tickets</Text>}
                            href='https://www.vancoevents.com/us/eventlist/katyisdhsathleticevents?embed=1' />
                    </CardBody>
                </Card>
                <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "900px" }}>
                    <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small'><Schedule size='small' /><Text weight={"bold"}>Schedules</Text></CardHeader>
                    <CardBody gap='small' pad="small">
                        <Tabs alignControls='start'>
                            <Tab title="Varsity" >
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=5&Tm=11815&L=1&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="Junior Varsity - Purple">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=3&Tm=11827&L=2&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="Junior Varsity - White">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=3&Tm=113319&L=2&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="9th grade - Purple">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=3&Tm=11833&L=3&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="9th grade - White">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=3&Tm=11835&L=3&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </Box>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Football as any);
