import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer
} from 'grommet';
import _ from 'lodash';
import { Close, Checkmark } from 'grommet-icons';
import { verifyRegistration, schoolEventSlice } from '../store/SchoolEvent'
import { siteSlice } from '../store/Site';
import { loadScript, HPEWindowInterface } from '../Utilities';
import QrCodeScanner from '../components/QrCodeScanner';
// import QRScanner from 'qr-code-scanner';

declare let window: HPEWindowInterface;

const mapDispatchToProps = {
    verifyRegistration,
    ...schoolEventSlice,
    ...siteSlice.actions
}

interface IEventCheckinProps {
}

interface EventCheckinState {
    checked: boolean;
    scanning: boolean;
    qrCode: string;
}

type EventCheckinProps = IEventCheckinProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class EventCheckin extends React.PureComponent<EventCheckinProps, EventCheckinState> {
    canvasRef: any;
    videoRef: any;

    constructor(props: EventCheckinProps) {
        super(props);

        this.state = {
            checked: false,
            scanning: false,
            qrCode: ''
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("events-menu")
        this.canvasRef = React.createRef();
        this.videoRef = React.createRef();
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSuccess = () => {
        this.setState({ checked: true })
    }

    onFailure = () => {
        this.setState({ checked: true })
    }

    onReadCode = () => {
        this.setState({ scanning: true, checked: false })
    }

    codeCaptured = (decodedText: any, decodedResult: any) => {

        this.setState({ scanning: false, qrCode: decodedText });
        this.props.verifyRegistration(decodedText, this.onSuccess, this.onFailure)
    }

    public render() {
        let attendee = this.props.events.attendee;

        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="small" >
            {this.state.checked && attendee && <Box gap='medium' align='center'>
                <Box align='center' justify='center' background={{ color: attendee.checkInDate ? 'yellow' : 'green' }} height="small" width="small">
                    <Checkmark size='xlarge' color='white' />
                </Box>
                <Box gap="small" fill>
                    <Text weight="bold">{attendee.name}</Text>
                    {attendee.checkInDate && <Text>has already checked in</Text>}
                    {!attendee.checkInDate && <Box gap="small">
                        <Text>is registered</Text>
                        {attendee.additionalAthletes.length !== 0 || attendee.additionalAthletes.length !== 0 && <Box>
                            <Text>Along with: </Text>
                            <ul>
                                {attendee.additionalAthletes.map(athlete => <li>{athlete.name} - {athlete.shirtSize}</li>)}
                                {attendee.additionalAttendees.map(additional => <li>{additional.name} -{additional.shirtSize}</li>)}
                            </ul>
                        </Box>}
                    </Box>}
                </Box>
            </Box>
            }
            {this.state.checked && !attendee && <Box gap="medium">
                <Box background={{ color: 'red' }} align='center' justify='center' height="small" width="small">
                    <Close size='xlarge' color='white' />
                </Box>
                <Text weight="bold">Registration not found</Text>
            </Box>
            }
            {this.state.scanning && <Box gap='small'>
                <Box width="medium" height="medium">
                    <QrCodeScanner fps={10} disableFlip={false} successCallback={this.codeCaptured} />
                </Box>
                <Button secondary label="Cancel" color="brand" onClick={() => { this.setState({ scanning: false }) }} />
            </Box>}
            {!this.state.scanning && <Button primary label="Check-in Attedee" color="brand" onClick={this.onReadCode} />}
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(EventCheckin as any);
