import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer
} from 'grommet';
import _ from 'lodash';
import EventRegistration from '../components/EventRegistration';
import { EventAttendee } from '../models/EventAttendee';
import { SchoolEvent } from '../models/SchoolEvent';
import { Close } from 'grommet-icons';
import { TypedValue } from "../models/Common"
import { registerAttendee, retrieveEvent, schoolEventSlice } from '../store/SchoolEvent'
import { siteSlice } from '../store/Site';

const mapDispatchToProps = {
    retrieveEvent,
    registerAttendee,
    ...schoolEventSlice,
    ...siteSlice.actions
}

interface IWomenInSportsProps {
}

interface WomenInSportsState {
    event: SchoolEvent | undefined;
    attendee: EventAttendee | undefined;
    register: boolean;
    registered: boolean;
}

type WomenInSportsProps = IWomenInSportsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class WomenInSports extends React.PureComponent<WomenInSportsProps, WomenInSportsState> {
    constructor(props: WomenInSportsProps) {
        super(props);

        this.state = {
            event: undefined,
            attendee: undefined,
            register: false,
            registered: false
        };

        this.props.setLoading(true);
        this.props.retrieveEvent("WomenInSports2022");
        this.props.setActiveMenu("events-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onClose = () => {
        this.setState({ register: false });
    }

    onCancel = () => {
        this.setState({ event: undefined });
    }

    onRegister = () => {
        this.setState({ register: true });
    }

    onSuccess = (attendee: EventAttendee) => {
        this.props.registerAttendee(attendee, this.onRegistered);
    }

    onRegistered = () => {
        let message = `Thanks ${this.props.events.attendee?.name}<br/><br/>You have been successfully registered.` +
            "<br/><br/>You should receive a confirmation email.";

        this.setState({ register: false });
        this.props.setMessage(message);
    }

    onFailure = () => {
    }

    public render() {

        return <Box align='center' pad={{ top: "small", bottom: "small" }} fill overflow="auto" gap="medium">
            {this.state.register && <Layer onEsc={this.onClose}>
                <EventRegistration onClose={this.onClose} onSuccess={this.onSuccess} onFailure={() => { }} {...this.props}></EventRegistration>
            </Layer>}
            <Box width="xlarge">
                <Image src='images/WomenSports.png' />
            </Box>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(WomenInSports as any);
