import * as React from 'react';
import { connect } from 'react-redux';
import { Box, Heading, Anchor, Text, Button, Image, Card, CardHeader, CardBody } from 'grommet';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { siteSlice } from '../store/Site';
import parse from 'html-react-parser';
import { Instagram, Facebook, Twitter, Ticket } from 'grommet-icons';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const mapDispatchToProps = {
  ...siteSlice.actions
}

interface IHomeProps {
}

type HomeProps = IHomeProps &
  ApplicationState // ... state we've requested from the Redux store
  & typeof mapDispatchToProps
  & RouteComponentProps<any>; // ... plus incoming routing parameters

class Home extends React.PureComponent<HomeProps, {}> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      attendee: undefined,
      showRegistration: false
    };

    this.props.setLoading(true);
    this.props.setActiveMenu("home-menu")
  }

  public componentDidMount() {
    this.props.setLoading(false);
  }

  render() {
    return <Box gap='medium' pad={{ left: 'medium', right: 'medium' }} fill overflow='auto'>
      <Box height="240px" background={{ size: "contain", image: "url('images/MAVS_1.jpg')" }} justify="center" />
      <Box direction='row-responsive' gap='medium' fill justify='center'>
        {/* <Box height={{ min: "260px" }} width={{ min: "320px", max: "320px" }} align="center">
          {parse(decodeURIComponent("%3Cdiv%20id%3D%22amznCharityBannerInner%22%3E%3Ca%20href%3D%22https%3A%2F%2Fsmile.amazon.com%2Fch%2F90-0175569%22%20target%3D%22" +
            "_blank%22%3E%3Cdiv%20class%3D%22text%22%20height%3D%22%22%3E%3Cdiv%20class%3D%22support-wrapper%22%3E%3Cdiv%20class%3D%22support%22%20style%3D%22font-size%3A%2021px" +
            "%3B%20line-height%3A%2024px%3B%20margin-top%3A%207px%3B%20margin-bottom%3A%207px%3B%22%3ESupport%20%3Cspan%20id%3D%22charity-name%22%20style%3D%22display%3A%20inline-" +
            "block%3B%22%3EMorton%20Ranch%20High%20School%20Athletic%20Booster%20Club.%3C%2Fspan%3E%3C%2Fdiv%3E%3C%2Fdiv%3E%3Cp%20class%3D%22when-shop%22%3EWhen%20you%20shop%20at%20%" +
            "3Cb%3Esmile.amazon.com%2C%3C%2Fb%3E%3C%2Fp%3E%3Cp%20class%3D%22donates%22%3EAmazon%20donates.%3C%2Fp%3E%3C%2Fdiv%3E%3C%2Fa%3E%3C%2Fdiv%3E%3Cstyle%3E%23amznCharityBannerInner" +
            "%7Bbackground-image%3Aurl(https%3A%2F%2Fm.media-amazon.com%2Fimages%2FG%2F01%2Fx-locale%2Fpaladin%2Fcharitycentral%2Fbanner-background-image._CB485922134_.png)%3Bwidth%3A300px" +
            "%3Bheight%3A250px%3Bposition%3Arelative%7D%23amznCharityBannerInner%20a%7Bdisplay%3Ablock%3Bwidth%3A100%25%3Bheight%3A100%25%3Bposition%3Arelative%3Bcolor%3A%23000%3Btext-" +
            "decoration%3Anone%7D.text%7Bposition%3Aabsolute%3Btop%3A20px%3Bleft%3A15px%3Bright%3A15px%3Bbottom%3A100px%7D.support-wrapper%7Boverflow%3Ahidden%3Bmax-height%3A86px%7D.support%7B" +
            "font-family%3AArial%2Csans%3Bfont-weight%3A700%3Bline-height%3A28px%3Bfont-size%3A25px%3Bcolor%3A%23333%3Btext-align%3Acenter%3Bmargin%3A0%3Bpadding%3A0%3Bbackground%3A0%200%7D." +
            "when-shop%7Bfont-family%3AArial%2Csans%3Bfont-size%3A15px%3Bfont-weight%3A400%3Bline-height%3A25px%3Bcolor%3A%23333%3Btext-align%3Acenter%3Bmargin%3A0%3Bpadding%3A0%3" +
            "Bbackground%3A0%200%7D.donates%7Bfont-family%3AArial%2Csans%3Bfont-size%3A15px%3Bfont-weight%3A400%3Bline-height%3A21px%3Bcolor%3A%23333%3Btext-align%3Acenter%3B" +
            "margin%3A0%3Bpadding%3A0%3Bbackground%3A0%200%7D%3C%2Fstyle%3E"))}
        </Box> */}
        <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "600px" }}>
          <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small'><Facebook size='small' /><Text weight={"bold"}>Facebook</Text></CardHeader>
          <CardBody>
            <Box pad="small" >
              <div className="fb-page" data-href="https://www.facebook.com/mrhsabc/" data-tabs="timeline"
                data-small-header="false" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true">
                <blockquote cite="https://www.facebook.com/mrhsabc/" className="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/mrhsabc/">morton ranch hs athletic booster club</a>
                </blockquote>
              </div>
            </Box>
          </CardBody>
        </Card>
        {/* <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "450px" }} >
          <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small' ><Twitter size='small' /><Text weight={"bold"}>Twitter</Text></CardHeader>
          <CardBody overflow='auto'>
            <TwitterTimelineEmbed sourceType="profile" screenName="MRHS_ABC" options={{ height: '480px' }} />
          </CardBody>
        </Card> */}
        {/* <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "450px" }} >
          <CardHeader background={{ color: 'brand' }} pad="small" justify='center'><Text weight={"bold"}>Intagram</Text></CardHeader>
          <CardBody overflow='auto'>
            <iframe src='https://www.instagram.com/mrhs_abc/' />
          </CardBody>
        </Card> */}
        {/* <Box gap='small' fill="vertical" border={{ color: 'brand', size: 'medium' }} align="center" width={{ min: "320px", max: "320px" }} height={{ min: "260px", max: '510px' }}>
          <Anchor icon={<Ticket size='large' />} target='_blank' label={<Text weight='bold'>Buy Tickets</Text>}
            href='https://www.etix.com/ticket/v/11016/academy-sports-outdoors-student-activities-complex' />
          <Button href='/promotions'>
            <Image fit='contain' src='images/YardCandyThumbnailpng.png' />
          </Button>
          <Box fill />
          <Box direction='row' gap='small' pad='medium' align='center' justify='center'>
            <Anchor icon={<Facebook size='large' />} href='https://www.facebook.com/mrhsabc/' target='_blank' />
            <Anchor icon={<Twitter size='large' />} href='https://twitter.com/MRHS_ABC' target='_blank' />
            <Anchor icon={<Instagram size='large' />} href='https://www.instagram.com/mrhs_abc/' target='_blank' />
          </Box>
        </Box> */}
      </Box>
    </Box>
  };
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  mapDispatchToProps)(Home as any);
