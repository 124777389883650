import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { Box, Form, Heading, Button, FormField, TextInput, List, Layer, Select, Header } from 'grommet';
import _ from 'lodash';
import { RelatedAttendee as RelatedAttendeeModel } from '../models/RelatedAttendee';

interface IRelatedAttendeeProps {
    onClose: Function;
    onSuccess: Function;
    onFailure: Function;
}

interface RelatedAttendeeState {
    relatedAttendee: RelatedAttendeeModel | undefined;
}

type RelatedAttendeeProps = IRelatedAttendeeProps &
    ApplicationState // ... state we've requested from the Redux store
    & RouteComponentProps<any>; // ... plus incoming routing parameters

export default class RelatedAttendee extends React.PureComponent<RelatedAttendeeProps, RelatedAttendeeState> {
    constructor(props: RelatedAttendeeProps) {
        super(props);

        this.state = {
            relatedAttendee: undefined
        };
    }

    public componentDidMount() {
    }

    onSubmit = () => {
        this.props.onSuccess(this.state.relatedAttendee as RelatedAttendeeModel);
    }

    onCancel = () => {
        this.props.onClose();
    }

    canSubmit = () => {
        let attendee = this.state.relatedAttendee;
        let result = attendee?.name && attendee.relationship && attendee.shirtSize;

        return result;
    }

    public render() {
        return <Box align='center' pad="small" fill gap="small">
            <Heading level="2" fill textAlign='center'>Additional Attendee</Heading>
            <Form value={this.state.relatedAttendee} onChange={nextValue => this.setState({ relatedAttendee: nextValue })} >
                <Box border pad="small" gap="medium">
                    <FormField name="name" htmlFor="name-input" required label="Name" >
                        <TextInput id="name-input" name="name"></TextInput>
                    </FormField>
                    <FormField name="relationship" htmlFor="relationship-input" required label="Relationship" >
                        <TextInput id="relationship-input" name="relationship"></TextInput>
                    </FormField>
                    <Box width={{ max: "medium" }}>
                        <FormField name="shirtSize" htmlFor="shirtSize-input" label="Shirt Size" >
                            <Select options={["XS", "S", "M", "L", "XL", "2XL", "3XL"]} name="shirtSize" dropHeight='small' />
                        </FormField>
                    </Box>
                </Box>
                <Box direction='row' justify='end' gap="small" pad="small">
                    <Button alignSelf='end' primary color="brand" label="Save" onClick={this.onSubmit} disabled={!this.canSubmit()} />
                    <Button alignSelf='end' secondary color="brand" label="Cancel" onClick={this.onCancel} />
                </Box>
            </Form>
        </Box>;
    }
};
