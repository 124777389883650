import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import {
    Box, Heading, Button, Anchor, FormField, TextInput, RadioButton, List, Layer, Tip,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer
} from 'grommet';
import _ from 'lodash';
import SchoolEvent from '../components/SchoolEvent';
import { EventAttendee } from '../models/EventAttendee';
import { SchoolEvent as SchoolEventModel } from '../models/SchoolEvent';
import { FormClose, FormEdit, FormView } from 'grommet-icons';
import { retrieveEvents, retrieveEvent, saveEvent, schoolEventSlice } from '../store/SchoolEvent'
import { siteSlice } from '../store/Site';
import { formatISO, parseJSON, format as formatDate } from 'date-fns'

const mapDispatchToProps = {
    push,
    retrieveEvent,
    retrieveEvents,
    saveEvent,
    ...schoolEventSlice.actions,
    ...siteSlice.actions
}

interface IEventManagementProps {
}

interface EventManagementState {
    event: SchoolEventModel | undefined;
    attendee: EventAttendee | undefined;
    showCreate: boolean;
}

type EventManagementProps = IEventManagementProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class EventManagement extends React.PureComponent<EventManagementProps, EventManagementState> {
    constructor(props: EventManagementProps) {
        super(props);

        this.state = {
            event: undefined,
            attendee: undefined,
            showCreate: false
        };

        this.props.setLoading(true);
        this.props.retrieveEvents();
        this.props.setActiveMenu("events-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onClose = () => {
        this.setState({ showCreate: false });
    }

    onSave = (event: SchoolEventModel) => {
        this.props.saveEvent(event);
        this.onClose();
    }

    onCancel = () => {
        this.setState({ event: undefined, showCreate: false });
    }

    onCreate = () => {
        this.setState({ showCreate: true });
    }

    onEdit = (event: SchoolEventModel) => {
        this.setState({ event: event, showCreate: true });
    }

    onViewAttendees = (event: SchoolEventModel) => {
        push(`/attendees/${event.id}`);
    }

    renderEvents = () => {
        let currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        let events = this.props.events.events;
        let rows = events.map((e, index) => <TableRow key={`event-${index}`}>
            <TableCell>{e.displayName}</TableCell>
            <TableCell>{formatDate(parseJSON(e.startDate), 'yyyy-MM-dd')}</TableCell>
            <TableCell>{formatDate(parseJSON(e.endDate), 'yyyy-MM-dd')}</TableCell>
            <TableCell>{currency.format(e.cost)}</TableCell>
            <TableCell>{e.contact}</TableCell>
            <TableCell>{e.contactEmail}</TableCell>
            <TableCell>
                <Box direction='row-responsive' gap="small">
                    <Tip content="Edit information">
                        <Button icon={<FormEdit />} onClick={() => { this.onEdit(e); }} />
                    </Tip>
                    <Tip content="View attendees">
                        <Button icon={<FormView />} onClick={() => { this.onViewAttendees(e); }} />
                    </Tip>
                </Box>
            </TableCell>
        </TableRow>);

        return rows;
    }

    public render() {
        return <Box pad="small" fill overflow="auto" gap="small">
            <Heading textAlign='center' fill>Event Management</Heading>
            {this.state.showCreate && <Layer onEsc={this.onCancel}>
                <Box fill>
                    <SchoolEvent data={this.state.event} onClose={this.onCancel} onSuccess={this.onSave}></SchoolEvent>
                </Box>
            </Layer>}
            <Box direction='row-responsive'>
                <Button primary label="Create New" onClick={this.onCreate} />
            </Box>
            <Table className='striped'>
                <TableHeader>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>Contact Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {this.renderEvents()}
                </TableBody>
            </Table>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(EventManagement as any);
