import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { VolunteerApplicant } from '../models/VolunteerApplicant';
import { setLoading, setError } from './Site';

let serialize = require('serialize-javascript');

export interface SocialMediaState {
    applicant: VolunteerApplicant | undefined;
    applicants: VolunteerApplicant[];
}

let initialState: SocialMediaState = {
    applicant: undefined,
    applicants: []
}

export const socialMediaSlice = createSlice({
    name: 'SocialMedia',
    initialState,
    reducers: {
        setSocialMediaApplicant: (state: SocialMediaState, action: any) => {
            state.applicant = action.payload;
        },
        setSocialMediaApplicants: (state: SocialMediaState, action: any) => {
            state.applicants = action.payload;
        }
    }
});

export const { setSocialMediaApplicant, setSocialMediaApplicants } = socialMediaSlice.actions;

export const saveApplicant = (applicant: VolunteerApplicant, callback?: Function): AppThunk => async (dispatch) => {
    let request = serialize(applicant);

    dispatch(setLoading(true));
    fetch('api/socialMedia/Save', {
        method: "POST",
        body: request,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setSocialMediaApplicant(data));
        dispatch(setLoading(false));
        if(callback){
            callback();
        }

    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const retrieveApplicants = (): AppThunk => async (dispatch) => {
    let request = serialize({});

    dispatch(setLoading(true));
    fetch('api/socialMedia/list', {
        method: "POST",
        body: request,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setSocialMediaApplicants(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export default socialMediaSlice.reducer;
