import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Box, Heading, Text, Card, CardBody, CardHeader, Accordion, AccordionPanel, Grid} from 'grommet';
import _ from 'lodash';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { retrieveMembers, membershipSlice } from '../../store/Membership';
import { Member } from '../../models/Member';

const mapDispatchToProps = {
    retrieveMembers,
    ...siteSlice.actions,
    ...membershipSlice.actions
}

interface IMembershipHistoryProps {
}

interface MembershipHistoryState {
}

type MembershipHistoryProps = IMembershipHistoryProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class MembershipHistory extends React.PureComponent<MembershipHistoryProps, MembershipHistoryState> {
    constructor(props: MembershipHistoryProps) {
        super(props);

        this.state = {
        };
        this.props.retrieveMembers();
        this.props.setLoading(true);
        this.props.setActiveMenu("membership-menu")
    }

    renderMembers = (year: number, members: Member[]) => {
        let lvlGrouping = _.groupBy(members, (member) => { return [member.level] });

        return <Grid gap={{ row: "medium", column: "medium" }} rows="auto" columns="300px" pad='small'>
            {
                _.map(lvlGrouping, (items, level) => {
                    return <Card key={`${year}-${level}`}>
                        <CardHeader background="brand" pad="small" height="xsmall" justify="center">
                            <Text weight="bold">{level}</Text>
                        </CardHeader>
                        <CardBody>
                            <Box height={{ max: 'medium' }} overflow='auto' pad='small' align='center'>
                                {
                                    _.map(items, (item) => <Text>{item.name}</Text>)
                                }
                            </Box>
                        </CardBody>
                    </Card>
                })
            }
        </Grid>
    }

    public render() {
        let currentYear = new Date().getUTCFullYear();
        let currentMembers = this.props.membership.members.filter((member: Member) => member.year === currentYear);
        let pastMembers = this.props.membership.members.filter((member: Member) => member.year !== currentYear);
        let pastGrouping = _.groupBy(pastMembers, (member) => { return [member.year] });

        return <Box align='center' fill overflow="auto" gap="small" pad={{ left: "small", right: 'small' }}>
            {
                this.renderMembers(currentYear, currentMembers)
            }
            <Accordion fill>
                {
                    _.map(pastGrouping, (members, year) => {
                        return <AccordionPanel key={year}
                            label={<Box fill justify='center'><Heading level='3' fill margin={{ vertical: "small" }} textAlign="center" color="brand">{`${year} - ${Number(year) + 1}`}</Heading></Box>} >
                            <Box fill>
                                {
                                    this.renderMembers(Number(year), members)
                                }
                            </Box>
                        </AccordionPanel>
                    })
                }
            </Accordion>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MembershipHistory as any);
