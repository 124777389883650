import { createSlice } from '@reduxjs/toolkit';

export interface SiteState {
    loading: boolean;
    error: string | undefined;
    activeMenu: string;
    message: string | undefined;
}

let initialState: SiteState = {
    loading: false,
    error: undefined,
    message: undefined,
    activeMenu:''
}

export const siteSlice = createSlice({
    name: 'Site',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        }
    }
});

export const { setLoading, setError, setMessage, setActiveMenu } = siteSlice.actions;

export default siteSlice.reducer;
