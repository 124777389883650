import React from 'react';
import { ApplicationState } from '../store';
import { siteSlice } from '../store/Site';
import { membershipSlice } from '../store/Membership';
import {
    Box, Form, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Paragraph, Footer, ResponsiveContext
} from 'grommet';
import Athlete from './Athlete';
import { Athlete as AthleteModel } from '../models/Athlete';
import { Member } from '../models/Member';
import { FormClose, FormEdit } from 'grommet-icons';
import { TypedValue as TypedValueModel } from '../models/Common';
import TypedValue from './TypedValue';
import parse from 'html-react-parser';

const mapDispatchToProps = {
    ...siteSlice.actions,
    ...membershipSlice.actions
}

interface IMembershipProps {
    member: Member | undefined;
    onSubmit: Function;
}

interface MembershipState {
    member: Member | undefined;
    athlete: AthleteModel | undefined;
    typedValue: TypedValueModel | undefined;
    addAthlete: boolean;
    submitPayment: boolean;
    paymentReceived: boolean;
    addTypedValue: boolean;
    typeLabel: string | undefined;
    typeOptions: string[];
    callback: Function | undefined;
    levels: any[];
}

type MembershipProps = IMembershipProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps;


export default class Membership extends React.PureComponent<MembershipProps, MembershipState> {
    constructor(props: MembershipProps) {
        super(props);

        this.state = {
            member: props.member,
            athlete: undefined,
            typedValue: undefined,
            addAthlete: false,
            submitPayment: false,
            paymentReceived: false,
            addTypedValue: false,
            callback: undefined,
            typeLabel: undefined,
            typeOptions: [],
            levels: [
                {
                    name: "Faculty/Staff",
                    cost: 10,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Faculty/Staff Lifetime",
                    cost: 50,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Varsity",
                    cost: 25,
                    benefits: ["Recognition in Morton Ranch Athletic Program and MRHSABC Website"]
                },
                {
                    name: "Purple Out",
                    cost: 100,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)"
                    ]
                },
                {
                    name: "Maverick Spirit",
                    cost: 200,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)",
                        "1/4 Page Ad in Sports Program"
                    ]
                },
                {
                    name: "Maverick Super Star",
                    cost: 350,
                    benefits: [
                        "Recognition in Morton Ranch Athletic Program and MRHSABC Website",
                        "$20.00 Free Spirit Wear Coupon",
                        "Maverick Head (info attached)",
                        "1/4 Page Ad in Sports Program",
                        "2 - KISD Athletic Passes"
                    ]
                }
            ]
        };
    }

    onClose = () => {
        this.setState({ addAthlete: false });
    }

    addAthlete = (athlete: AthleteModel) => {
        let member = { ...this.state.member } as Member;

        if (!member.athletes) {
            member.athletes = [];
        }
        member.athletes.push(athlete);
        this.setState({ addAthlete: false, member: member, athlete: undefined });
    }

    editAthlete = (index: number) => {
        let member = { ...this.state.member } as Member;
        let athlete = member.athletes[index];

        member.athletes.splice(index, 1)
        this.setState({ member: member, athlete: athlete, addAthlete: true });
    }

    deleteAthlete = (index: number) => {
        let member = { ...this.state.member } as Member;

        member.athletes.splice(index, 1)
        this.setState({ member: member });
    }

    renderAthlete = (_item: any, index: number) => {
        return <Box direction='row-responsive' gap="xsmall">
            <Button icon={<FormEdit />} onClick={() => { this.editAthlete(index) }} />
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteAthlete(index) }} />
        </Box>
    }

    renderSports = (item: any) => {
        return <Text>{item.sports.join(", ")}</Text>;
    }

    onLevelChange = (level: string) => {
        let member = { ...this.state.member } as Member;

        member.level = level;
        this.setState({ member: member });
    }

    onOptionSelected = (option: string, checked: boolean) => {
        let member = { ...this.state.member } as Member;

        member.volunteerSelections = member.volunteerSelections ?? [];
        if (checked) {
            member.volunteerSelections.push(option);
        }
        else {
            member.volunteerSelections = member.volunteerSelections.filter((opt) => opt != option);
        }
        this.setState({ member: member });
    }

    onCancel = () => {
        this.setState({ member: undefined });
    }

    onAddEmail = () => {
        this.setState({
            addTypedValue: true, callback: this.addEmail, typeLabel: "Email",
            typeOptions: ["Home", "Work", "Other"]
        });
    }

    addEmail = (email: TypedValueModel) => {
        let member = { ...this.state.member } as Member;

        if (!member.emails) {
            member.emails = [];
        }
        member.emails.push(email);
        this.setState({ addTypedValue: false, member: member, typedValue: undefined });
    }

    renderEmail = (_item: any, index: number) => {
        return <Box direction='row-responsive' gap="medium">
            <Button icon={<FormEdit />} onClick={() => { this.editEmail(index) }} />
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteEmail(index) }} />
        </Box>
    }

    deleteEmail = (index: number) => {
        let member = { ...this.state.member } as Member;

        member.emails.splice(index, 1)
        this.setState({ member: member });
    }

    editEmail = (index: number) => {
        let member = { ...this.state.member } as Member;
        let email = member.emails[index];

        member.emails.splice(index, 1)
        this.setState({ member: member, typedValue: email, addTypedValue: true });
    }

    onAddNumber = () => {
        this.setState({
            addTypedValue: true, callback: this.addNumber, typeLabel: "Number",
            typeOptions: ["Mobile", "Home", "Work", "Other"]
        });
    }

    addNumber = (number: TypedValueModel) => {
        let member = { ...this.state.member } as Member;

        if (!member.contactNumbers) {
            member.contactNumbers = [];
        }
        member.contactNumbers.push(number);
        this.setState({ addTypedValue: false, member: member, typedValue: undefined });
    }

    renderNumber = (_item: any, index: number) => {
        return <Box direction='row-responsive' gap="medium">
            <Button icon={<FormEdit />} onClick={() => { this.editNumber(index) }} />
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteNumber(index) }} />
        </Box>
    }

    deleteNumber = (index: number) => {
        let member = { ...this.state.member } as Member;

        member.contactNumbers.splice(index, 1)
        this.setState({ member: member });
    }

    editNumber = (index: number) => {
        let member = { ...this.state.member } as Member;
        let contactNumber = member.contactNumbers[index];

        member.contactNumbers.splice(index, 1)
        this.setState({ member: member, typedValue: contactNumber, addTypedValue: true });
    }

    canSubmit = () => {
        let member = this.state.member;

        return member?.name && member.address && member.city && member.zip &&
            (member.emails && member.emails.length !== 0) &&
            (member.contactNumbers && member.contactNumbers.length !== 0) &&
            (member.athletes && member.athletes.length !== 0) && member.level;
    }

    public render() {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        let levels = [
            {
                name: "Faculty/Staff",
                cost: 10,
                benefits: ["Recognition in Morton Ranch Athletic Program and MRHS ABC Website"]
            },
            {
                name: "Varsity",
                cost: 25,
                benefits: ["Recognition in Morton Ranch Athletic Program and MRHS ABC Website"]
            },
            {
                name: "Faculty/Staff Lifetime",
                cost: 50,
                benefits: ["Recognition in Morton Ranch Athletic Program and MRHS ABC Website"]
            },
            {
                name: "Purple Out",
                cost: 100,
                benefits: [
                    "Recognition in Morton Ranch Athletic Program and MRHS ABC Website",
                    "$25.00 Free Spirit Wear Coupon"
                ]
            },
            {
                name: "Maverick Spirit",
                cost: 200,
                benefits: [
                    "Recognition in Morton Ranch Athletic Program and MRHS ABC Website",
                    "$25.00 Free Spirit Wear Coupon",
                    "<a target='_blank' href='/documents/2022 Football Program Ad.pdf'>1/4 Page Ad</a> in Sports Program"
                ]
            },
            {
                name: "Maverick Super Star",
                cost: 350,
                benefits: [
                    "Recognition in Morton Ranch Athletic Program and MRHS ABC Website",
                    "$25.00 Free Spirit Wear Coupon",
                    "<a target='_blank' href='/documents/2022 Football Program Ad.pdf'>1/2 Page Ad</a> in Sports Program",
                    "2 - KISD Athletic Passes"
                ]
            },
            {
                name: "Maverick Pride",
                cost: 500,
                benefits: [
                    "Recognition in Morton Ranch Athletic Program and MRHS ABC Website",
                    "$25.00 Free Spirit Wear Coupon",
                    "<a target='_blank' href='/documents/2022 Football Program Ad.pdf'>Full Page Ad</a> in Sports Program",
                    "2 - KISD Athletic Passes"
                ]
            }
        ]
        let volunteerOptions = [
            {
                position: "Parent Rep",
                description: "Work with coaches to help plan team Banquets/Locker Dec./ etc"
            },
            {
                position: "Spirit Wear / Booster Sales",
                description: "Help sell tickets and Spirit Wear items at football games and other sporting and school events"
            },
            {
                position: "Tunnel Crew",
                description: "Help set-up and tear down the Maverick Tunnel for Varsity games"
            }
        ];
        let member = this.state.member;

        return <Box fill overflow="auto" gap="small" width={{ max: 'xlarge' }}>
            {this.state.addAthlete && <Layer>
                <Athlete data={this.state.athlete} onClose={this.onClose} callback={this.addAthlete} {...this.props} />
            </Layer>}
            {this.state.addTypedValue && <Layer>
                <TypedValue data={this.state.typedValue} onClose={() => { this.setState({ addTypedValue: false }); }}
                    label={this.state.typeLabel} options={this.state.typeOptions} callback={this.state.callback as Function} {...this.props} />
            </Layer>}
            <Box pad="small" fill>
                <Form value={member} onChange={nextValue => this.setState({ member: nextValue })} onReset={() => this.setState({ member: undefined })}
                    onSubmit={({ value }) => { this.props.onSubmit(value) }}>
                    <Box pad="small" gap="medium">
                        <Box gap="small" pad="small" fill border>
                            <Box background="brand">
                                <Heading textAlign='center' level="2" fill>Member Information</Heading>
                            </Box>
                            <FormField fill name="name" htmlFor="name-input" required label="Name" >
                                <TextInput id="name-input" name="name" value={member?.name}></TextInput>
                            </FormField>
                            <Box direction="row-responsive" gap="small" fill="horizontal">
                                <FormField fill name="address" htmlFor="address-input" required label="Address" >
                                    <TextInput id="address-input" name="address" ></TextInput>
                                </FormField>
                                <Box width="medium">
                                    <FormField fill name="city" htmlFor="city-input" required label="City" >
                                        <TextInput id="city-input" name="city"></TextInput>
                                    </FormField>
                                </Box>
                                <Box width="small">
                                    <FormField fill name="zip" htmlFor="zip-input" required label="Zip" >
                                        <TextInput id="zip-input" name="zip" type="number"></TextInput>
                                    </FormField>
                                </Box>
                            </Box>
                            <Box direction="row-responsive" gap="small">
                                <Box direction="row-responsive" gap="small" fill="horizontal" align='center'>
                                    <FormField fill name="emails" htmlFor="emails-input" required label="Email(s)" >
                                        <Box fill height={{ min: 'xsmall', max: "medium" }} overflow="auto">
                                            <List primaryKey="value" secondaryKey="type" data={member?.emails} action={this.renderEmail} />
                                        </Box>
                                    </FormField>
                                    <Box width="small">
                                        <Button primary label="Add Email" onClick={this.onAddEmail} />
                                    </Box>
                                </Box>
                                <Box direction="row-responsive" gap="small" fill="horizontal" align='center'>
                                    <FormField fill name="contactNumbers" htmlFor="contactNumbers-input" required label="Contact Number(s)" >
                                        <Box fill height={{ min: 'xsmall', max: "medium" }} overflow="auto">
                                            <List primaryKey="value" secondaryKey="type" data={member?.contactNumbers} action={this.renderNumber} />
                                        </Box>
                                    </FormField>
                                    <Box width="220px">
                                        <Button primary label="Add Number" onClick={this.onAddNumber} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction="row-responsive" gap="small" fill="horizontal" align='center'>
                                <FormField name="athletes" htmlFor="athletes-input" required label="Athlete(s)" >
                                    <Box fill height={{ min: 'xsmall', max: "medium" }} width={{ min: 'medium' }} overflow="auto">
                                        <List primaryKey="name" secondaryKey={this.renderSports} data={member?.athletes} action={this.renderAthlete} />
                                    </Box>
                                </FormField>
                                <Box width="145px">
                                    <Button primary label="Add Athlete" onClick={() => { this.setState({ addAthlete: true }); }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box gap="small" pad="small" fill>
                            <Box background="brand" pad={{ top: "small" }} >
                                <Heading textAlign='center' level="2" fill>Membership Options</Heading>
                            </Box>
                            <Table className='striped'>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell><Text weight="bold" textAlign='center'>Level</Text></TableCell>
                                        <TableCell><Text weight="bold" textAlign='center'>Cost</Text></TableCell>
                                        <TableCell fill><Text weight="bold" textAlign='center'>Benefits</Text></TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {levels.map((level, index) => <TableRow key={`level-${index}`}>
                                        <TableCell>
                                            <RadioButton name="level" checked={member?.level == level.name} onChange={() => this.onLevelChange(level.name)} />
                                        </TableCell>
                                        <TableCell verticalAlign='middle'><Text weight="bold">{level.name}</Text></TableCell>
                                        <TableCell verticalAlign='middle' align='center'><Text textAlign='end'>{formatter.format(level.cost)}</Text></TableCell>
                                        <TableCell>
                                            <Box>
                                                <ul>
                                                    {level.benefits.map((benefit, ndx) => <li key={`level-${index}-benefit-${ndx}`}>{parse(benefit)}</li>)}
                                                </ul>
                                            </Box>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                            <Box gap='small' border pad='small'>
                                <Text weight="bold">Maverick Spirit, Maverick Super Star, and Maverick Pride:</Text>
                                <Paragraph textAlign='center' fill>
                                    Fill out <Anchor target='_blank' href='/documents/2022 Football Program Ad.pdf'>Football Program Ad</Anchor> form
                                    and turn in by mid August. Make checks payable to MRHS ABC. Turn in to any
                                    board member or mail to P.O. Box 6231, Katy TX, 77491.
                                </Paragraph>
                            </Box>
                        </Box>
                        <Box fill gap="small">
                            <Box background="brand">
                                <Heading textAlign='center' level="2" fill>Volunteer Options</Heading>
                            </Box>
                            <Table className='striped'>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell><Text weight="bold" textAlign='center'>Position</Text></TableCell>
                                        <TableCell><Text weight="bold" textAlign='center'>Description</Text></TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {volunteerOptions.map((option, index) => {
                                        let checked = member?.volunteerSelections && member?.volunteerSelections.indexOf(option.position) !== -1;

                                        return <TableRow key={`option-${index}`}>
                                            <TableCell>
                                                <CheckBox checked={checked !== undefined && checked}
                                                    onChange={(event) => this.onOptionSelected(option.position, event.target.checked)} />
                                            </TableCell>
                                            <TableCell verticalAlign='middle' fill><Text weight="bold">{option.position}</Text></TableCell>
                                            <TableCell><Text>{option.description}</Text></TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <Footer direction='row-responsive' gap="small" pad="small" justify='end'>
                            <Button type="submit" primary label="Submit Application" disabled={!this.canSubmit()} />
                            <Button type="reset" secondary label="Clear Application" />
                        </Footer>
                    </Box>
                </Form>
            </Box>
        </Box>;
    }
}
