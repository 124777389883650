import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Box, Heading, Text, DataTable, Accordion, AccordionPanel, NameValueList, NameValuePair, ThemeContext } from 'grommet';
import _ from 'lodash';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { retrieveMembers } from '../../store/Membership';
import { Member } from '../../models/Member';

const mapDispatchToProps = {
    retrieveMembers,
    ...siteSlice.actions,
}

interface IMembershipListProps {
}

interface MembershipListState {
}

type MembershipListProps = IMembershipListProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class MembershipList extends React.PureComponent<MembershipListProps, MembershipListState> {
    constructor(props: MembershipListProps) {
        super(props);

        this.state = {
        };
        this.props.retrieveMembers();
        this.props.setLoading(true);
        this.props.setActiveMenu("membership-menu")
    }

    public render() {
        let currentYear = new Date().getUTCFullYear();
        let membership = this.props.membership.members;
        let currentMembers = _.filter(membership, (member: Member) => member.year === currentYear);
        let pastMembers = _.filter(membership, (member: Member) => member.year !== currentYear);
        let pastGrouping = _.groupBy(membership, (member) => { return [member.year] });
        let sortedYears = _.orderBy(Object.keys(pastGrouping), [year => year], ["desc"]);
        let columns = [
            {
                property: 'name',
                header: <Text>Name</Text>,
            }, {
                property: 'email',
                header: <Text>Email</Text>,
                render: (datum: any) => (
                    <Box width="medium">
                        <NameValueList>
                            {_.map(datum.emails, email => <NameValuePair name={email.type}>
                                <Text>{email.value}</Text>
                            </NameValuePair>
                            )}
                        </NameValueList>
                    </Box>)
            }, {
                property: 'level',
                header: <Text>Level</Text>,
            }];

        return <Box fill overflow="auto" gap="small" pad={{ left: "small", right: 'small' }}>
            <Accordion fill>
                {
                    _.map(sortedYears, (year) => {
                        let members = pastGrouping[year];

                        return <AccordionPanel key={year}
                            label={<Box fill justify='center'>
                                <Heading level='3' fill margin={{ vertical: "small" }} textAlign="center" color="brand">
                                    {`${year} - ${Number(year) + 1}`}
                                </Heading>
                            </Box>} >
                            <Box fill background='white'>
                                <DataTable data={members} columns={columns} fill pin className='striped' />
                            </Box>
                        </AccordionPanel>
                    })
                }
            </Accordion>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MembershipList as any);
