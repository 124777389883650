import * as React from 'react';
import NavComponent from './NavComponent';
import { Grommet, Box, Header, Heading, Footer } from 'grommet';
import { base } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const mrhsTheme = deepMerge(base, {
    global: {
        colors: {
            "brand": {
                dark: "#472E8D",
                light: "#472E8D"
            }
        }
    },
    tab: {
        active: { color: "text", background: "brand" },
        pad: 'xsmall'
    },
    formField: {
        content: {
            margin: {
                vertical: "xsmall"
            },
            pad: void 0
        },
        border: {
            error: {
                color: "border-strong"
            },
            color: "border",
            side: "all"
        },
        disabled: {
            background: {
                color: void 0
            },
            border: {
                color: "border-weak"
            },
            label: {
                color: "text-weak"
            }
        },
        error: {
            background: {
                color: "validation-critical"
            },
            container: {
                gap: "xsmall"
            },
            size: "xsmall",
            color: "text",
            margin: {
                bottom: "xsmall",
                top: "none",
                horizontal: "none"
            }
        },
        // focus: {
        //     border: {
        //         color: "border-strong"
        //     }
        // },
        help: {
            size: "xsmall",
            color: "text",
            margin: "none"
        },
        info: {
            size: "xsmall",
            color: "text",
            margin: {
                bottom: "xsmall",
                top: "none",
                horizontal: "none"
            }
        },
        label: {
            size: "xsmall",
            color: "text",
            margin: {
                bottom: "none",
                top: "xsmall",
                horizontal: "none"
            },
            requiredIndicator: !0,
            weight: 500
        },
        margin: {
            bottom: "none"
        },
        round: "4px"
    }
});

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <React.Fragment>
                <Grommet theme={mrhsTheme} full>
                    <Box fill width={{ min: "small" }} >
                        <Header height={{ min: "xsmall" }} elevation='small' background={{ color: "brand" }} fill="horizontal" justify='center' pad='small'>
                            <Heading fill textAlign='center'>Morton Ranch High School Athletic Booster Club</Heading>
                        </Header>
                        <NavComponent />
                        <Box fill pad={{ top: "medium", bottom: "small" }} overflow="auto" align='center'>
                            {this.props.children}
                        </Box>
                        <Footer height={{ min: "xxsmall" }} background={{ color: "brand" }} >
                            <Box pad={{ left: "small" }}>
                                <span id="siteseal">
                                </span>
                            </Box>
                        </Footer>
                    </Box>
                </Grommet>
            </React.Fragment >
        );
    }
}