import React from 'react';
import { Box, Form, FormField, TextInput, Button, Select, Heading, Header, Footer} from 'grommet';
import { Close } from 'grommet-icons';
import _ from 'lodash';
import { Athlete as AthleteModel } from '../models/Athlete';
import { ApplicationState } from '../store';
import {Sports} from '../models/Common';

interface IAthleteProps {
    data?: AthleteModel | undefined;
    callback: Function;
    onClose?: any;
}

interface AthleteState {
    athlete: AthleteModel | undefined;
    showSelect: boolean;
}


type AthleteProps =
    IAthleteProps // ... state we've requested from the Redux store
    & ApplicationState; // ... state we've requested from the Redux store

export default class Athlete extends React.PureComponent<AthleteProps, AthleteState> {
    constructor(props: AthleteProps) {
        super(props);

        this.state = {
            athlete: this.props.data,
            showSelect: this.props.data?.sports.length == 0
        };
    }


    onAddAthlete = () => {
        this.props.callback(this.state.athlete);
    }

    showSports = (show: boolean) => {
        this.setState({ showSelect: show });
        if (show) {
            setTimeout(() => jQuery("#sports-select").trigger("click"), 250);
        }
    }

    public render() {
        return <Box width={{ min: "medium" }}>
            <Header direction="row" elevation="small" pad="small" justify="between">
                <Heading level="4" fill>Add Athlete</Heading>
                <Box align="end" justify="start">
                    <Button type="button" icon={<Close size="small" />} onClick={this.props.onClose} />
                </Box>
            </Header>
            <Box pad="small" gap="medium" height={{ max: "medium" }} overflow="auto" border>
                <Form value={this.state.athlete} onChange={nextValue => this.setState({ athlete: nextValue })} >
                    <FormField name="name" htmlFor="name-input" label="Name" required >
                        <TextInput id="name-input" name="name" ></TextInput>
                    </FormField>
                    <Box direction='row-responsive' gap='medium'>
                        <FormField name="gender" htmlFor="gender-input" label="Gender" required >
                            <Select options={["Male", "Female"]} name="gender" />
                        </FormField>
                        <FormField name="grade" htmlFor="grade-input" label="Grade" required >
                            <Select options={["9", "10", "11", "12"]} name="grade" />
                        </FormField>
                    </Box>
                    <FormField name="sports" htmlFor="sports-input" label="Sports(s)" required >
                        {!this.state.showSelect && <TextInput value={this.state.athlete?.sports ? this.state.athlete?.sports.join(", ") : undefined}
                            onFocus={() => this.showSports(true)} />}
                        {this.state.showSelect && <Select id="sports-select" options={Sports} multiple name="sports" onClose={() => this.showSports(false)} />}
                    </FormField>
                </Form>
            </Box>
            <Footer direction="row" gap="small" pad="small" justify="end">
                <Button primary label="Save" color="brand" onClick={this.onAddAthlete} />
                <Button secondary label="Cancel" onClick={this.props.onClose} color="brand" />
            </Footer>
        </Box>
    }
}
