import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/';
import { Box, Layer, Spinner, Header, Paragraph, Footer, Text, Button } from 'grommet';
import NavMenu from './NavMenu';
import { setError, setMessage } from '../store/Site';
import parse from 'html-react-parser';

const mapDispatchToProps = {
    setError,
    setMessage
}

export type NavComponentProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class NavComponent extends React.PureComponent<NavComponentProps, any> {
    public render() {
        return <Box fill="horizontal">
            {this.props.site.loading &&
                <Layer>
                    <Box align='center' justify='center'>
                        <Spinner size="large" />
                    </Box>
                </Layer>
            }
            {this.props.site.error && <Layer>
                <Box round={{ "size": "small", "corner": "top" }}>
                    <Header background="#A2423D" round={{ "size": "small", "corner": "top" }}>
                        <Box fill="horizontal"><Text textAlign="center" color="white">Error</Text></Box>
                    </Header>
                    <Box pad="small" alignContent="center">
                        <Paragraph fill color="red" textAlign="center">{this.props.site.error}</Paragraph>
                    </Box>
                    <Footer fill="horizontal" pad="small" justify="end">
                        <Box width="xsmall" >
                            <Button secondary color="#A2423D" label="Dismiss" onClick={() => { this.props.setError(undefined); }} />
                        </Box>
                    </Footer>
                </Box>
            </Layer>}
            {this.props.site.message && <Layer>
                <Box>
                    <Header background="brand">
                        <Box fill="horizontal"><Text textAlign="center" color="white">Information</Text></Box>
                    </Header>
                    <Box pad="small" alignContent="center">
                        <Paragraph fill textAlign="center">{parse(this.props.site.message)}</Paragraph>
                    </Box>
                    <Footer fill="horizontal" pad="small" justify="end">
                        <Box width="xsmall" >
                            <Button primary color="brand" label="OK" onClick={() => { this.props.setMessage(undefined) }} />
                        </Box>
                    </Footer>
                </Box>
            </Layer>}
            <Box pad={{ left: "medium", right: "medium" }} >
                <NavMenu {...this.props} />
            </Box>
        </Box>
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(NavComponent as any);
