import * as React from 'react';
import './NavMenu.css';
import { Box, Menu, Nav as NavBox, DropButton, Anchor, ResponsiveContext } from 'grommet';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { siteSlice } from '../store/Site';
import { ApplicationState } from '../store';
import classNames from 'classnames';
import { Sports } from '../models/Common';
import { firstBy } from 'thenby';
import { Menu as MenuIcon } from 'grommet-icons';

const mapDispatchToProps = {
    push,
    ...siteSlice.actions
}
type NavMenuProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class NavMenu extends React.PureComponent<NavMenuProps, { isOpen: boolean }> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            isOpen: false
        };

    }

    renderMenus = () => {
        let site = this.props.site;
        let sportList = ["Football", "Volleyball"];
        let sports = sportList.sort(firstBy(v => v)).map((sport) => {
            return { key: `${sport}-menu`, label: sport, href: `/sports/${sport.toLowerCase()}` }
        });

        return [
            <Anchor key="home-menu" href='/' label="Home" className={classNames({ "active-menu": site.activeMenu === 'home-menu' })} />,
            <Menu key="membership-menu" label="Membership" className={classNames({ "active-menu": site.activeMenu === 'membership-menu' })}
                items={[
                    { label: "Join", href: '/membership/join' }
                    // { label: "History", href: '/membership/history' }
                ]} />,
            <Menu key="sports-menu" label="Sports" className={classNames({ "active-menu": site.activeMenu === 'sports-menu' })}
                items={sports} />,
            <Menu key="programs-menu" label="Programs" className={classNames({ "active-menu": site.activeMenu === 'programs-menu' })}
                items={[
                    { label: "Programs", href: '/programs' }
                ]} />,
            // <Menu key="events-menu" label="Events" className={classNames({ "active-menu": site.activeMenu === 'events-menu' })}
            //     items={[
            //         { label: "National Girls & Women In Sports", href: '/womenInSports' }
            //     ]} />,
            <Menu key="scholarship-menu" label="Scholarship" className={classNames({ "active-menu": site.activeMenu === 'scholarship-menu' })}
                items={[
                    { label: "Download Application", href: '/documents/MRHS ABC Scholarship Instructions and Application 2023.pdf' }
                ]} />,
            <Menu key="promotions-menu" label="Promotions" className={classNames({ "active-menu": site.activeMenu === 'promotions-menu' })}
                items={[
                    { label: "Yard Candy", href: '/promotions/yardCandy' }
                ]} />,
            <Menu key="socialMedia-menu" label="Social Media" className={classNames({ "active-menu": site.activeMenu === 'socialMedia-menu' })}
                items={[
                    { label: "Flyer", href: '/socialMedia/flyer' },
                    { label: "Volunteer Application", href: '/socialMedia/application' }
                ]} />
        ];
    }

    public render() {
        return <ResponsiveContext.Consumer>
            {size => (((size === "small") || (size === "xsmall"))
                ? <Box>
                    <DropButton alignSelf="end" icon={<MenuIcon color="brand" size="medium" />}
                        dropAlign={{ top: 'bottom', right: 'right' }}
                        dropContent={
                            <Box>
                                {this.renderMenus()}
                            </Box>
                        } />
                </Box>
                : <NavBox direction="row" pad={{ left: "small", bottom: "xsmall" }} align="center" fill="horizontal" justify='center' gap='small'>
                    {this.renderMenus()}
                </NavBox>)
            }
        </ResponsiveContext.Consumer>;
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(NavMenu as any);
