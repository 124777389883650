import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { registerMember, membershipSlice } from '../../store/Membership';
import { Box, Select, Heading, TextInput, Button, Header, Layer, FormField, Footer, List } from 'grommet';
import { Member } from '../../models/Member';
import { Close, FormClose } from 'grommet-icons';
import Membership from '../../components/Membership';

const mapDispatchToProps = {
    registerMember,
    ...siteSlice.actions,
    ...membershipSlice.actions
}

interface MembershipManualEntryState {
    member: Member | undefined;
    addPass: boolean;
    pass: number | undefined;
    collectAdditionalInfo: boolean;
}

type MembershipManualEntryProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class MembershipManualEntry extends React.PureComponent<MembershipManualEntryProps, MembershipManualEntryState> {
    constructor(props: MembershipManualEntryProps) {
        super(props);

        this.state = {
            member: undefined,
            addPass: false,
            pass: undefined,
            collectAdditionalInfo: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("admin-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSubmit = (value: Member) => {
        this.props.registerMember(value, this.onMemberRegistered);
        // this.setState({ member: value, collectAdditionalInfo: true })
    }

    onInformationCollected = () => {
        this.props.registerMember(this.state.member as Member, this.onMemberRegistered);
    }

    onMemberRegistered = () => {
        this.setState({ collectAdditionalInfo: false })
        this.props.setMessage("Member information has been saved");
    }

    setPaymentType = (paymentType: string) => {
        let member = { ...this.state.member } as Member;

        member.paymentType = paymentType;
        this.setState({ member: member })
    }

    setPaymentId = (paymentId: string) => {
        let member = { ...this.state.member } as Member;

        member.paymentId = paymentId;
        this.setState({ member: member })
    }

    setDateReceived = (dateReceived: any) => {
        let member = { ...this.state.member } as Member;

        member.dateReceived = dateReceived;
        this.setState({ member: member })
    }

    onCancel = () => {
        this.setState({ member: undefined });
    }

    onCancelPayment = () => {
        this.setState({ collectAdditionalInfo: false })
    }

    deletePass = (index: number) => {
        let member = { ...this.state.member } as Member;

        member.passes.splice(index, 1)
        this.setState({ member: member });
    }

    onPassAdded = () => {
        let member = { ...this.state.member } as Member;

        if (!member.passes) {
            member.passes = [];
        }
        member.passes.push(this.state.pass as number);
        this.setState({ member: member, addPass: false, collectAdditionalInfo: true, pass: undefined })
    }

    onCancelPass = () => {
        this.setState({ addPass: false, collectAdditionalInfo: true, pass: undefined })
    }

    renderPass = (_item: any, index: number) => {
        return <Box direction='row-responsive' gap="xsmall">
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deletePass(index) }} />
        </Box>
    }

    public render() {
        let member = this.state.member;
        let canAddPass = ["Maverick Super Star", "Maverick Pride"].indexOf(member?.level as string) !== -1;

        return <Box align='center' fill overflow="auto" gap="small" pad="small">
            <Heading fill textAlign='center'>Membership Entry</Heading>
            {this.state.addPass && <Layer>
                <Box width="259px" height={{ max: "medium" }} gap="small" overflow={{ vertical: "auto" }} >
                    <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                        <Heading level="4" fill textAlign='center'>Add Pass</Heading>
                        <Box align="end" justify="start">
                            <Button type="button" icon={<Close size="small" />} onClick={this.onCancelPass} />
                        </Box>
                    </Header>
                    <Box pad="small">
                        <TextInput type="number" value={this.state.pass} width="small"
                            onChange={(event) => this.setState({ pass: Number(event.target.value) })} />
                    </Box>
                    <Footer direction="row" gap="small" pad="small" justify="end">
                        <Button type="button" primary color="brand" label="Add" onClick={this.onPassAdded} />
                        <Button type="button" secondary color="brand" label="Cancel" onClick={this.onCancelPass} />
                    </Footer>
                </Box>
            </Layer>}
            {this.state.collectAdditionalInfo && <Layer>
                <Box width="medium" height={{ max: "large" }} gap="small" overflow={{ vertical: "auto" }}>
                    <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                        <Heading level="4" fill textAlign='center'>Enter Additional Information</Heading>
                        <Box align="end" justify="start">
                            <Button type="button" icon={<Close size="small" />} onClick={this.onCancelPayment} />
                        </Box>
                    </Header>
                    <Box pad="small" gap="small">
                        <FormField fill name="dateReceived" htmlFor="dateReceived-input" required label="Date Received" >
                            <TextInput type="date" id="dateReceived-input" name="dateReceived" value={member?.dateReceived} width="medium"
                                onChange={(event) => { this.setDateReceived(event.target.value) }} />
                        </FormField>
                        <FormField fill name="paymentType" htmlFor="paymentType-input" required label="Payment Type" >
                            <Select options={['Cash', 'Check', 'PayPal']} value={member?.paymentType}
                                onChange={({ option }) => this.setPaymentType(option)} />
                        </FormField>
                        {member?.paymentType && member?.paymentType !== 'Cash' && <FormField fill name="paymentId" htmlFor="paymentId-input" label="Payment Id" >
                            <TextInput id="paymentId-input" name="paymentId" value={member?.paymentId}
                                onChange={(event) => { this.setPaymentId(event.target.value) }} />
                        </FormField>}
                        {canAddPass && <Box direction="row-responsive" gap="small" align='center' height={{ max: 'medium' }} overflow='auto'>
                            <FormField name="passes" htmlFor="passes-input" label="Passes" >
                                <Box width={{ min: "150px" }}>
                                    <List data={member?.passes} action={this.renderPass} />
                                </Box>
                            </FormField>
                            <Box >
                                <Button primary color="brand" label="Add Pass" onClick={() => { this.setState({ addPass: true, collectAdditionalInfo: false }); }} />
                            </Box>
                        </Box>}
                    </Box>
                    <Footer direction="row" gap="small" pad="small" justify="end">
                        <Button type="button" primary color="brand" label="OK" onClick={this.onInformationCollected} />
                        <Button type="button" secondary color="brand" label="Cancel" onClick={this.onCancelPayment} />
                    </Footer>
                </Box>
            </Layer>}
            <Membership member={this.state.member} onSubmit={this.onSubmit} {...this.props} />
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MembershipManualEntry as any);
