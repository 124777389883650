import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { Box, Form, Heading, Button, FormField, TextInput, List, Select, Text, CheckBox } from 'grommet';
import _ from 'lodash';
import { EventAttendee } from '../models/EventAttendee';
import { Checkbox, Close, FormClose } from 'grommet-icons';

interface IViewRegistrationProps {
    data: EventAttendee;
    onClose: Function;
}

interface ViewRegistrationState {
}

type ViewRegistrationProps = IViewRegistrationProps &
    ApplicationState; // ... state we've requested from the Redux store

export default class ViewRegistration extends React.PureComponent<ViewRegistrationProps, ViewRegistrationState> {
    constructor(props: ViewRegistrationProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() {
    }

    onCancel = () => {
        this.props.onClose();
    }

    renderEmail = (item: any, index: number) => {
        return <Box direction='row-responsive' gap="small">
            <Box pad={{ top: "10px" }}>
                <Text>{item.relationship}</Text>
            </Box>
            <Box pad={{ top: "10px" }}>
                <Text>{item.shirtSize}</Text>
            </Box>
        </Box>
    }

    renderAdditional = (item: any, index: number) => {
        return <Box direction='row-responsive' gap="small">
            <Box pad={{ top: "10px" }}>
                <Text>{item.relationship}</Text>
            </Box>
            <Box pad={{ top: "10px" }}>
                <Text>{item.shirtSize}</Text>
            </Box>
        </Box>
    }

    renderAthlete = (item: any, index: number) => {
        return <Box direction='row-responsive' gap="small">
            <Text>{item.shirtSize}</Text>
        </Box>
    }

    public render() {
        let attendee = this.props.data;
        let schoolEvent = attendee.event;
        let eventCost = schoolEvent?.cost ?? 0.00;
        let total = eventCost + eventCost * (attendee.additionalAttendees ? attendee.additionalAttendees.length : 0) +
            eventCost * (attendee.additionalAthletes ? attendee.additionalAthletes.length : 0);
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        let isDisabled = this.props.data !== undefined;

        return <Box fill align='center' pad="small" gap="small" >
            <Heading fill level={2} textAlign='center'>{this.props.events.event?.internalName}</Heading>
            <Heading fill level={3} textAlign='center'>Registration Information</Heading>
            <Box fill overflow="auto" gap="small" height={{ max: 'large' }}>
                <Form value={attendee} onChange={nextValue => this.setState({ attendee: nextValue })} >
                    <Box fill gap="small" pad="medium" >
                        <FormField name="name" htmlFor="name-input" label="Name" >
                            <TextInput id="name-input" name="name" disabled={isDisabled} />
                        </FormField>
                        <Box direction="row-responsive" gap="small">
                            <FormField name="address" htmlFor="address-input" label="Address" >
                                <TextInput id="address-input" name="address" disabled={isDisabled} />
                            </FormField>
                            <Box width="small" >
                                <FormField name="city" htmlFor="city-input" label="City" >
                                    <TextInput id="city-input" name="city" disabled={isDisabled} />
                                </FormField>
                            </Box>
                            <Box width="small" >
                                <FormField name="zip" htmlFor="zip-input" label="Zip" >
                                    <TextInput type="number" id="zip-input" name="zip" minLength={5} maxLength={5} disabled={isDisabled} />
                                </FormField>
                            </Box>
                        </Box>
                        {attendee.isStudent && <Box gap="small">
                            <Box direction="row-responsive" gap="small" >
                                <FormField name="grade" htmlFor="grade-input" label="Grade" >
                                    <TextInput type="number" id="grade-input" name="grade" min={6} disabled={isDisabled} />
                                </FormField>
                                <FormField name="age" htmlFor="age-input" label="Age" >
                                    <TextInput type="number" id="age-input" name="age" min={11} disabled={isDisabled} />
                                </FormField>
                                <FormField name="school" htmlFor="school-input" label="School" >
                                    <TextInput id="school-input" name="school" disabled={isDisabled} />
                                </FormField>
                            </Box>
                            <FormField name="sports" htmlFor="sports-input" label="Sports(s)" >
                                <TextInput value={attendee.sports ? attendee.sports.join(", ") : undefined} disabled={isDisabled} />
                            </FormField>
                        </Box>}
                        <Box direction="row-responsive" gap="small" fill="horizontal" >
                            <FormField name="emails" htmlFor="emails-input" label="Email(s)">
                                <List primaryKey="value" secondaryKey="type" key="email-list" data={attendee.emails}
                                    action={this.renderEmail} />
                            </FormField>
                            <FormField name="contactNumbers" htmlFor="contactNumbers-input" label="Contact Phone Number(s)" >
                                <List primaryKey="value" secondaryKey="type" key="number-list" data={attendee.contactNumbers} />
                            </FormField>
                        </Box>
                        <Box width="small" >
                            <FormField name="shirtSize" htmlFor="shirtSize-input" label="Shirt Size" >
                                <Select options={["XS", "S", "M", "L", "XL", "2XL", "3XL"]} name="shirtSize" dropHeight='small' disabled={isDisabled} />
                            </FormField>
                        </Box>
                        <FormField name="additionalAthletes" label="Additional Athletes" >
                            <List data={attendee.additionalAthletes} action={this.renderAthlete} />
                        </FormField>
                        <FormField name="additionalAttendees" label="Additional Attendees" >
                            <List data={attendee.additionalAttendees} action={this.renderAdditional} />
                        </FormField>
                        <Box direction='row-responsive' justify='end' gap="small" pad={{ top: "small", bottom: "small" }}>
                            <Text weight="bold">Registration Total: {formatter.format(total)}</Text>
                        </Box>
                        <Box direction='row-responsive' justify='end' gap="small" pad="small">
                            <Button alignSelf='end' secondary color="brand" label={isDisabled ? "Close" : "Cancel"} onClick={this.onCancel} />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Box >;
    }
};
