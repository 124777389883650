import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { Box, Image } from 'grommet';
import _ from 'lodash';
import { siteSlice } from '../store/Site';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface IPromotionsProps {
}

interface PromotionsState {
    activeTab: number;
}

type PromotionsProps = IPromotionsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Promotions extends React.PureComponent<PromotionsProps, PromotionsState> {
    constructor(props: PromotionsProps) {
        super(props);

        let tab = this.props.match
            ? this.props.match.params.activeTab
            : null;
        let activeTab = 0;

        switch (tab) {
            case 'yardCandy':
                activeTab = 0;
                break;
        }
        this.state = {
            activeTab: activeTab
        };
        this.props.setLoading(true);
        this.props.setActiveMenu("promotions-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }
    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
    }

    public render() {

        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="medium">
            {/* <Tabs alignControls='start' activeIndex={this.state.activeTab} onActive={this.onActivateTab}>
                <Tab title="Grad Candy">
                    <Image fit="contain" src='images/YardCandyGraduation.jpg' />
                </Tab>
                <Tab title="Yard Candy"> */}
            <Image fit="contain" src='images/MRHS ABC 2022-2023.png' />
            {/* </Tab>
            </Tabs> */}
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Promotions as any);
