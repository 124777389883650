import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { siteSlice } from '../store/Site';
import { Box, Heading, Button, Layer, Header, Form, FormField, TextInput, Text, Table, TableBody, TableRow, TableCell, TableHeader } from 'grommet';
import { Close } from 'grommet-icons';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { EventTicket } from '../models/EventTicket';
import { saveTicket } from '../store/TicketedEvent';
import _ from "lodash";

const mapDispatchToProps = {
    saveTicket,
    ...siteSlice.actions
}

interface StampedeState {
    ticketInfo?: any;
    ticket?: EventTicket;
    submitPayment: boolean;
    callback?: Function;
}

type StampedeProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class StampedeTickets extends React.PureComponent<StampedeProps, StampedeState> {
    constructor(props: StampedeProps) {
        super(props);

        this.state = {
            submitPayment: false
        }
        this.props.setLoading(true);
        this.props.setActiveMenu("event-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }


    onCancelPayment = () => {
        this.setState({ submitPayment: false })
        this.props.setMessage("Purchase cancelled");
    }

    createOrder = (_data: any, actions: any) => {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let description = `${year} Maverick Stampede`;
        let amount = (this.state.ticketInfo?.adult ? this.state.ticketInfo?.adult * 10 : 0) +
            (this.state.ticketInfo?.pass ? this.state.ticketInfo?.pass * 15 : 0) +
            (this.state.ticketInfo?.student ? this.state.ticketInfo?.student * 5 : 0);
        let items = [];

        if (this.state.ticketInfo.adult) {
            items.push({
                name: 'Adult Ticket',
                quantity: this.state.ticketInfo.adult,
                unit_amount: {
                    currency_code: "USD",
                    value: "10"
                },
                category: "DIGITAL_GOODS"
            })
        }
        if (this.state.ticketInfo.pass) {
            items.push({
                name: 'Adult 2-Day Ticket',
                quantity: this.state.ticketInfo.pass,
                unit_amount: {
                    currency_code: "USD",
                    value: "15"
                },
                category: "DIGITAL_GOODS"
            })
        }
        if (this.state.ticketInfo.student) {
            items.push({
                name: 'Student Ticket',
                quantity: this.state.ticketInfo.student,
                unit_amount: {
                    currency_code: "USD",
                    value: "5"
                },
                category: "DIGITAL_GOODS"
            })
        }
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: description,
                    amount: {
                        currency_code: "USD",
                        value: amount,
                        breakdown: {
                            item_total: {
                                currency_code: "USD",
                                value: amount,
                            }
                        }
                    },
                    items: items
                },
            ]
        });
    }

    onOrderApproved = (_data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
            let items = _.map(details.purchase_units[0].items, (item: any) => `${item.quantity} - ${item.name}`);
            let ticket = {
                event: details.purchase_units[0].description,
                email: details.payer.email_address,
                paymentId: details.id,
                description: items.join('<br/>')
            }

            this.setState({ submitPayment: false })
            this.props.saveTicket(ticket);
        });
    }

    onPaymentError = (err: any) => {
        this.setState({ submitPayment: false })
        this.props.setError(err.message);
    }

    public render() {
        let adultQty = (this.state.ticketInfo?.adult ? this.state.ticketInfo?.adult : 0);
        let passQty = (this.state.ticketInfo?.pass ? this.state.ticketInfo?.pass : 0);
        let studentQty = (this.state.ticketInfo?.student ? this.state.ticketInfo?.student : 0);
        let adultTotal = adultQty * 10;
        let passTotal = passQty * 15;
        let studentTotal = studentQty * 5;
        let ticketTotal = adultTotal + passTotal + studentTotal;
        let qtyTotal = Number(adultQty) + Number(passQty) + Number(studentQty);

        return <Box fill overflow="auto" gap="small" pad="small" align='center'>
            <Heading fill textAlign='center'>Maverick Stampede Ticket Order Form</Heading>
            <Form onChange={(newValue) => { this.setState({ ticketInfo: newValue }) }} onSubmit={() => { this.setState({ submitPayment: true }) }}
                onReset={() => { this.setState({ ticketInfo: undefined }) }}>
                <Table margin='xxsmall'>
                    <TableHeader>
                        <TableRow>
                            <TableCell>
                                <Text>Ticket</Text>
                            </TableCell>
                            <TableCell width='xxsmall'>
                                <Text>Cost</Text>
                            </TableCell>
                            <TableCell width='xxsmall'>
                                <Text>Qty</Text>
                            </TableCell>
                            <TableCell width='xsmall'>
                                <Text>Total</Text>
                            </TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow key='adult-ticket'>
                            <TableCell>
                                <Text>Adult</Text>
                            </TableCell>
                            <TableCell>
                                <Text>$10</Text>
                            </TableCell>
                            <TableCell>
                                <TextInput type="number" name="adult" min={0} />
                            </TableCell>
                            <TableCell>
                                <Text weight='bold'>{`$${ adultTotal.toFixed(2) }`}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow key='pass-ticket'>
                            <TableCell>
                                <Text>2-Day</Text>
                            </TableCell>
                            <TableCell>
                                <Text>$15</Text>
                            </TableCell>
                            <TableCell>
                                <TextInput type="number" name="pass" min={0} />
                            </TableCell>
                            <TableCell>
                                <Text weight='bold'>{`$${ studentTotal.toFixed(2) }`}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow key='student-ticket'>
                            <TableCell>
                                <Text>Student</Text>
                            </TableCell>
                            <TableCell>
                                <Text>$5</Text>
                            </TableCell>
                            <TableCell>
                                <TextInput type="number" name="student" min={0} />
                            </TableCell>
                            <TableCell>
                                <Text weight='bold'>{`$${ studentTotal.toFixed(2) }`}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow key='ticket-total'>
                            <TableCell>
                                <Text weight='bold'>Total</Text>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell alignContent='center'>
                                <Text weight='bold'>{qtyTotal}</Text>
                            </TableCell>
                            <TableCell>
                                <Text weight='bold'>{`$${ ticketTotal.toFixed(2) }`}</Text>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box direction='row' gap='small' justify='end' pad={{ top: 'medium' }}>
                    <Button type='submit' primary label='Purchase' disabled={qtyTotal == 0} />
                    <Button type='reset' secondary label='Reset' />
                </Box>
            </Form>
            {this.state.submitPayment && <Layer>
                <Box width="medium" height={{ max: "large" }} gap="small" overflow={{ vertical: "auto" }}>
                    <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                        <Heading level="4" fill textAlign='center'>Submit Payment</Heading>
                        <Box align="end" justify="start">
                            <Button type="button" icon={<Close size="small" />} onClick={this.onCancelPayment} />
                        </Box>
                    </Header>
                    <Box pad="small" gap="small">
                        <PayPalButtons onCancel={this.onCancelPayment} createOrder={this.createOrder}
                            onApprove={this.onOrderApproved} onError={this.onPaymentError} />
                    </Box>
                </Box>
            </Layer>}
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(StampedeTickets as any);
