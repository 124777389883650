import React from 'react';
import { Box, Form, FormField, TextInput, Button, Heading, Header, Footer} from 'grommet';
import { Close } from 'grommet-icons';
import { Reference as ReferenceModel } from '../models/Reference';
import { ApplicationState } from '../store';

interface IReferenceProps {
    data?: ReferenceModel;
    callback: Function;
    onClose?: any;
}

interface ReferenceState {
    reference: ReferenceModel | undefined;
}


type ReferenceProps =
    IReferenceProps // ... state we've requested from the Redux store
    & ApplicationState; // ... state we've requested from the Redux store

export default class Reference extends React.PureComponent<IReferenceProps, ReferenceState> {
    constructor(props: ReferenceProps) {
        super(props);

        this.state = {
            reference: this.props.data
        };
    }


    onAddReference = () => {
        this.props.callback(this.state.reference);
    }

    public render() {
        return <Box width={{ min: "medium" }}>
            <Header direction="row" elevation="small" pad="small" justify="between">
                <Heading level="4" fill>Add Reference</Heading>
                <Box align="end" justify="start">
                    <Button type="button" icon={<Close size="small" />} onClick={this.props.onClose} />
                </Box>
            </Header>
            <Box pad="small" gap="medium" height={{ max: "medium" }} overflow="auto" border>
                <Form value={this.state.reference} onChange={nextValue => this.setState({ reference: nextValue })} >
                    <FormField name="name" htmlFor="name-input" label="Name" required >
                        <TextInput id="name-input" name="name" ></TextInput>
                    </FormField>
                    <FormField name="title" htmlFor="title-input" label="Tile" required >
                        <TextInput id="title-input" name="title" ></TextInput>
                    </FormField>
                    <FormField name="email" htmlFor="email-input" label="Email" required >
                        <TextInput id="email-input" name="email" ></TextInput>
                    </FormField>
                </Form>
            </Box>
            <Footer direction="row" gap="small" pad="small" justify="end">
                <Button primary label="Save" color="brand" onClick={this.onAddReference} />
                <Button secondary label="Cancel" onClick={this.props.onClose} color="brand" />
            </Footer>
        </Box>
    }
}
