import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { Box, Form, Heading, Button, FormField, TextInput, List, Layer, Select, Header, Text, CheckBox } from 'grommet';
import _ from 'lodash';
import { EventAttendee } from '../models/EventAttendee';
import { RelatedAttendee as RelatedAttendeeModel } from '../models/RelatedAttendee';
import RelatedAttendee from './RelatedAttendee';
import { Checkbox, Close, FormClose } from 'grommet-icons';
import { PayPalButtons } from "@paypal/react-paypal-js";
import TypedValue from "./TypedValue";
import { TypedValue as TypedValueModel } from '../models/Common';
import { Sports } from '../models/Common';
import { AdditionalAthlete as AdditionalAthleteModel } from "../models/AdditionalAthlete"
import AdditionalAthlete from '../components/AdditionalAthlete';

interface IEventRegistrationProps {
    attendee?: EventAttendee;
    onClose: Function;
    onSuccess?: Function;
    onFailure?: Function;
}

interface EventRegistrationState {
    attendee: EventAttendee | undefined;
    typedValue: TypedValueModel | undefined;
    submitPayment: boolean;
    typeLabel: string | undefined;
    typeOptions: string[];
    valueType: string,
    addTypedValue: boolean;
    addAthlete: boolean;
    callback: Function | undefined;
    showSelect: boolean;
    addAdditional: boolean;
}

type EventRegistrationProps = IEventRegistrationProps &
    ApplicationState // ... state we've requested from the Redux store
    & RouteComponentProps<any>; // ... plus incoming routing parameters

export default class EventRegistration extends React.PureComponent<EventRegistrationProps, EventRegistrationState> {
    constructor(props: EventRegistrationProps) {
        super(props);

        this.state = {
            attendee: this.props.attendee ? { ...this.props.attendee } : undefined,
            typedValue: undefined,
            submitPayment: false,
            typeLabel: undefined,
            typeOptions: [],
            valueType: "",
            addTypedValue: false,
            addAthlete: false,
            callback: undefined,
            showSelect: true,
            addAdditional: false
        };
    }

    public componentDidMount() {
    }

    onSubmit = () => {
        // let attendee = { ...this.state.attendee } as EventAttendee;

        this.setState({ submitPayment: true });
        // attendee.orderId = "1234567";
        // this.props.onSuccess(attendee as EventAttendee);
    }

    onCancelPayment = () => {
        this.setState({ submitPayment: false });
    }

    onCancel = () => {
        this.props.onClose();
    }

    onAddEmail = () => {
        this.setState({
            addTypedValue: true, callback: this.addEmail, typeLabel: "Email",
            typeOptions: ["Personal", "Work", "Other"],
            valueType: "text"
        });
    }

    addEmail = (email: TypedValueModel) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        if (!attendee.emails) {
            attendee.emails = [];
        }
        attendee.emails.push(email);
        this.setState({ addTypedValue: false, attendee: attendee });
    }

    renderEmail = (item: any, index: number) => {
        return <Box direction='row-responsive' gap="small">
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteEmail(index) }} />
        </Box>
    }

    deleteEmail = (index: number) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        attendee.emails.splice(index, 1)
        this.setState({ attendee: attendee });
    }

    onAddNumber = () => {
        this.setState({
            addTypedValue: true, callback: this.addNumber, typeLabel: "Number",
            typeOptions: ["Mobile", "Home", "Work", "Other"],
            valueType: "number"
        });;
    }

    addNumber = (number: TypedValueModel) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        if (!attendee.contactNumbers) {
            attendee.contactNumbers = [];
        }
        attendee.contactNumbers.push(number);
        this.setState({ addTypedValue: false, attendee: attendee });
    }

    renderNumber = (item: any, index: number) => {
        return <Box direction='row-responsive' gap="small">
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteNumber(index) }} />
        </Box>
    }

    deleteNumber = (index: number) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        attendee.contactNumbers.splice(index, 1)
        this.setState({ attendee: attendee });
    }

    showSports = (show: boolean) => {
        this.setState({ showSelect: show });
        if (show) {
            setTimeout(() => jQuery("#sports-select").trigger("click"), 250);
        }
    }

    createOrder = (data: any, actions: any) => {
        let shoolEvent = this.props.events.event;
        let eventCost = shoolEvent?.cost ?? 0.00;
        let total = eventCost + eventCost * (this.state.attendee?.additionalAttendees ? this.state.attendee.additionalAttendees.length : 0) +
            eventCost * (this.state.attendee?.additionalAthletes ? this.state.attendee.additionalAthletes.length : 0);

        return actions.order.create({
            purchase_units: [
                {
                    description: `${shoolEvent?.displayName}`,
                    amount: {
                        currency_code: "USD",
                        value: total
                    }
                }
            ]
        });
    }

    onOrderApproved = (data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
            let attendee = { ...this.state.attendee } as EventAttendee;

            attendee.orderId = details.id;
            if (this.props.onSuccess) {
                this.props.onSuccess(attendee);
            }
        });
    }

    canSubmit = () => {
        let attendee = this.state.attendee;
        let result = attendee?.name && attendee.address && attendee.city && attendee.zip &&
            (attendee.emails && attendee.emails.length !== 0) &&
            (attendee.contactNumbers && attendee.contactNumbers.length !== 0) &&
            (!attendee.isStudent || (attendee?.isStudent && attendee.age && attendee.grade && attendee.school));

        return result;
    }

    onAddAdditional = (relatedAttendee: RelatedAttendeeModel) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        if (!attendee.additionalAttendees) {
            attendee.additionalAttendees = [];
        }
        attendee.additionalAttendees.push(relatedAttendee);
        this.setState({ attendee: attendee, addAdditional: false });
    }

    deleteAdditional = (index: number) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        attendee.additionalAttendees.splice(index, 1)
        this.setState({ attendee: attendee });
    }

    renderAdditional = (item: any, index: number) => {
        let isDisabled = this.props.attendee !== undefined;

        return <Box direction='row-responsive' gap="small">
            <Box pad={{ top: "10px" }}>
                <Text>{item.relationship}</Text>
            </Box>
            <Box pad={{ top: "10px" }}>
                <Text>{item.shirtSize}</Text>
            </Box>
            {!isDisabled && <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteAdditional(index) }} />}
        </Box>
    }

    onClose = () => {
        this.setState({ addAthlete: false });
    }

    addAthlete = (athlete: AdditionalAthleteModel) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        if (!attendee.additionalAthletes) {
            attendee.additionalAthletes = [];
        }
        attendee.additionalAthletes.push(athlete);
        this.setState({ addAthlete: false, attendee: attendee });
    }

    deleteAthlete = (index: number) => {
        let attendee = { ...this.state.attendee } as EventAttendee;

        attendee.additionalAthletes.splice(index, 1)
        this.setState({ attendee: attendee });
    }

    renderAthlete = (item: any, index: number) => {
        let isDisabled = this.props.attendee !== undefined;

        return <Box direction='row-responsive' gap="small">
            <Box pad={{ top: "10px" }}>
                <Text>{item.shirtSize}</Text>
            </Box>
            {!isDisabled && <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteAthlete(index) }} />}
        </Box>
    }

    public render() {
        let attendee = this.state.attendee;
        let shoolEvent = this.props.events.event;
        let eventCost = shoolEvent?.cost ?? 0.00;
        let total = eventCost + eventCost * (this.state.attendee?.additionalAttendees ? this.state.attendee.additionalAttendees.length : 0) +
            eventCost * (this.state.attendee?.additionalAthletes ? this.state.attendee.additionalAthletes.length : 0);
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        let isDisabled = this.props.attendee !== undefined;

        return <Box fill align='center' pad="small" gap="small" >
            <Heading fill level={2} textAlign='center'>{this.props.events.event?.internalName}</Heading>
            <Heading fill level={3} textAlign='center'>Registration Form</Heading>
            <Box fill overflow="auto" gap="small" height={{ max: 'medium' }}>
                {this.state.submitPayment && <Layer>
                    <Box width="medium" height={{ max: "large" }} gap="small" overflow={{ vertical: "auto" }}>
                        <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                            <Heading level="4" fill textAlign='center'>Submit Payment</Heading>
                            <Box align="end" justify="start">
                                <Button type="button" icon={<Close size="small" />} onClick={this.onCancelPayment} />
                            </Box>
                        </Header>
                        <Box pad="small" gap="small">
                            <PayPalButtons onCancel={this.onCancelPayment} createOrder={this.createOrder} onApprove={this.onOrderApproved} />
                        </Box>
                    </Box>
                </Layer>}
                {this.state.addTypedValue && <Layer>
                    <Box width={{ max: "medium" }}>
                        <TypedValue data={this.state.typedValue} {...this.props} onClose={() => { this.setState({ addTypedValue: false }); }}
                            label={this.state.typeLabel} options={this.state.typeOptions} callback={this.state.callback as Function} type={this.state.valueType} />
                    </Box>
                </Layer>}
                {this.state.addAdditional && <Layer>
                    <RelatedAttendee {...this.props} onClose={() => { this.setState({ addAdditional: false }); }}
                        onFailure={this.props.onFailure ? this.props.onFailure : () => { }} onSuccess={this.onAddAdditional} />
                </Layer>}
                {this.state.addAthlete && <Layer>
                    <AdditionalAthlete {...this.props} callback={this.addAthlete} onClose={this.onClose} />
                </Layer>}
                <Form value={attendee} onChange={nextValue => this.setState({ attendee: nextValue })} >
                    <Box fill gap="small" pad="medium" >
                        <FormField name="name" htmlFor="name-input" required label="Name" disabled={isDisabled}>
                            <TextInput id="name-input" name="name"></TextInput>
                        </FormField>
                        <Box direction="row-responsive" gap="small">
                            <FormField name="address" htmlFor="address-input" required label="Address" disabled={isDisabled}>
                                <TextInput id="address-input" name="address" ></TextInput>
                            </FormField>
                            <Box width="small" >
                                <FormField name="city" htmlFor="city-input" required label="City" disabled={isDisabled}>
                                    <TextInput id="city-input" name="city"></TextInput>
                                </FormField>
                            </Box>
                            <Box width="small" >
                                <FormField name="zip" htmlFor="zip-input" required label="Zip" disabled={isDisabled}>
                                    <TextInput type="number" id="zip-input" name="zip" minLength={5} maxLength={5}></TextInput>
                                </FormField>
                            </Box>
                        </Box>
                        <CheckBox name="isStudent" label="I am a student" disabled={isDisabled} />
                        {attendee?.isStudent && <Box gap="small">
                            <Box direction="row-responsive" gap="small" >
                                <FormField name="grade" htmlFor="grade-input" required label="Grade" disabled={isDisabled}>
                                    <TextInput type="number" id="grade-input" name="grade" min={6}></TextInput>
                                </FormField>
                                <FormField name="age" htmlFor="age-input" required label="Age" disabled={isDisabled}>
                                    <TextInput type="number" id="age-input" name="age" min={11}></TextInput>
                                </FormField>
                                <FormField name="school" htmlFor="school-input" required label="School" disabled={isDisabled}>
                                    <TextInput id="school-input" name="school"></TextInput>
                                </FormField>
                            </Box>
                            <FormField name="sports" htmlFor="sports-input" label="Sports(s)" disabled={isDisabled}>
                                {!this.state.showSelect && <TextInput value={this.state.attendee?.sports ? this.state.attendee?.sports.join(", ") : undefined}
                                    onFocus={() => this.showSports(true)} />}
                                {this.state.showSelect && <Select id="sports-select" options={Sports} multiple name="sports" onClose={() => this.showSports(false)}
                                    dropHeight='medium' />}
                            </FormField>
                        </Box>}
                        <Box direction="row-responsive" gap="small" fill="horizontal" >
                            <Box direction="row-responsive" fill gap="small">
                                {this.state.attendee?.emails && this.state.attendee?.emails.length !== 0 &&
                                    <FormField name="emails" htmlFor="emails-input" required label="Email(s)">
                                        <List primaryKey="value" secondaryKey="type" key="email-list" data={this.state.attendee?.emails}
                                            action={this.renderEmail} />
                                    </FormField>}
                                <Box width="135px" pad={{ top: "20px" }} >
                                    <Button primary color="brand" label="Add Email" onClick={this.onAddEmail} disabled={isDisabled} />
                                </Box>
                            </Box>
                            <Box direction="row-responsive" fill gap="small">
                                {this.state.attendee?.contactNumbers && this.state.attendee?.contactNumbers.length !== 0 &&
                                    <FormField name="contactNumbers" htmlFor="contactNumbers-input" required label="Contact Phone Number(s)" fill >
                                        <List primaryKey="value" secondaryKey="type" key="number-list" data={this.state.attendee?.contactNumbers}
                                            action={this.renderNumber} />
                                    </FormField>}
                                <Box width="155px" pad={{ top: "20px" }}>
                                    <Button primary color="brand" label="Add Number" onClick={this.onAddNumber} disabled={isDisabled} />
                                </Box>
                            </Box>
                        </Box>
                        <Box width="small" >
                            <FormField name="shirtSize" htmlFor="shirtSize-input" label="Shirt Size" >
                                <Select options={["XS", "S", "M", "L", "XL", "2XL", "3XL"]} name="shirtSize" dropHeight='small' disabled={isDisabled} />
                            </FormField>
                        </Box>
                        <Box direction='row-responsive' justify='between' gap="small">
                            <List alignSelf='start' data={this.state.attendee?.additionalAthletes} action={this.renderAthlete} />
                            <Button alignSelf='end' primary color="brand" label="Add Additional Athlete" onClick={() => { this.setState({ addAthlete: true }); }}
                                disabled={isDisabled} />
                        </Box>
                        <Box direction='row-responsive' justify='between' gap="small">
                            <List alignSelf='start' data={this.state.attendee?.additionalAttendees} action={this.renderAdditional} />
                            <Button alignSelf='end' primary color="brand" label="Add Additional Attendee" onClick={() => { this.setState({ addAdditional: true }) }}
                                disabled={isDisabled} />
                        </Box>
                        <Box direction='row-responsive' justify='end' gap="small" pad={{ top: "small", bottom: "small" }}>
                            <Text weight="bold">Registration Total: {formatter.format(total)}</Text>
                        </Box>
                        <Box direction='row-responsive' justify='end' gap="small" pad="small">
                            <Button alignSelf='end' primary color="brand" label="Submit Registration" onClick={this.onSubmit} disabled={!this.canSubmit() || isDisabled} />
                            <Button alignSelf='end' secondary color="brand" label={isDisabled ? "Close" : "Cancel"} onClick={this.onCancel} />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Box >;
    }
};
