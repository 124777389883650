import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { siteSlice } from '../store/Site';
import { Box, Heading, Button, Layer, CheckBox, Form, FormField, TextInput, TextArea, Paragraph, List } from 'grommet';
import { VolunteerApplicant } from '../models/VolunteerApplicant';
import Reference from '../components/Reference';
import { Reference as ReferenceModel } from '../models/Reference';
import { FormClose } from 'grommet-icons';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface SocialMediaVolunteerState {
    applicant: VolunteerApplicant | undefined;
    addReference: boolean;
}

interface ISocialMediaVolunteerProps {
    data?: VolunteerApplicant;
    onSubmit: Function;
}


type SocialMediaVolunteerProps = ISocialMediaVolunteerProps
    & ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


export default class SocialMediaVolunteer extends React.PureComponent<SocialMediaVolunteerProps, SocialMediaVolunteerState> {
    constructor(props: SocialMediaVolunteerProps) {
        super(props);

        this.state = {
            applicant: this.props.data,
            addReference: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("socialMedia-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSubmit = (value: VolunteerApplicant) => {
        this.props.onSubmit(value);
        this.setState({ applicant: value })
    }

    onCancel = () => {
        this.setState({ applicant: undefined });
    }

    renderReference = (_item: any, index: number) => {
        return <Box direction='row-responsive' gap="medium">
            <Button icon={<FormClose color="red"></FormClose>} onClick={() => { this.deleteReference(index) }} />
        </Box>
    }

    addReference = (reference: ReferenceModel) => {
        let applicant = { ...this.state.applicant } as VolunteerApplicant;

        if (!applicant.references) {
            applicant.references = [];
        }
        applicant.references.push(reference);
        this.setState({ addReference: false, applicant: applicant });
    }


    deleteReference = (index: number) => {
        let applicant = { ...this.state.applicant } as VolunteerApplicant;

        applicant.references.splice(index, 1)
        this.setState({ applicant: applicant });
    }

    onClose = () => {
        this.setState({ addReference: false });
    }

    onAcknowledged = (checked: boolean) => {
        let applicant = { ...this.state.applicant } as VolunteerApplicant;

        applicant.acknowledged = checked;
        this.setState({ applicant: applicant });
    }

    canSubmit = () => {
        let applicant = { ...this.state.applicant } as VolunteerApplicant;

        return applicant?.name && applicant.email && applicant.grade && applicant.phoneNumber &&
            applicant.activities && applicant.acknowledged &&
            (applicant.references && applicant.references.length >= 2);
    }

    public render() {
        let applicant = this.state.applicant;

        return <Box align='center' fill overflow="auto" gap="small" width={{ max: 'large' }}>
            {this.state.addReference && <Layer>
                <Reference onClose={this.onClose} callback={this.addReference} />
            </Layer>}
            <Form value={applicant} onChange={nextValue => this.setState({ applicant: nextValue })} onReset={() => this.setState({ applicant: undefined })}
                onSubmit={({ value }) => { this.onSubmit(value) }}>
                <Box pad="small" border>
                    <Box background="brand">
                        <Heading textAlign='center' level="2" fill>Student Information</Heading>
                    </Box>
                    <Box pad={{ bottom: 'small' }}>
                        <FormField fill name="name" htmlFor="name-input" required label="Name" >
                            <TextInput id="name-input" name="name" value={applicant?.name}></TextInput>
                        </FormField>
                        <FormField fill name="email" htmlFor="email-input" required label="Email" >
                            <TextInput id="email-input" name="email" value={applicant?.email}></TextInput>
                        </FormField>
                        <Box direction='row-responsive' gap='small'>
                            <FormField fill name="grade" htmlFor="grade-input" required label="Grade" >
                                <TextInput type="number" id="grade-input" name="grade" value={applicant?.grade}></TextInput>
                            </FormField>
                            <FormField fill name="phoneNumber" htmlFor="phoneNumber-input" required label="Phone Number" >
                                <TextInput id="phoneNumber-input" name="phoneNumber" value={applicant?.phoneNumber}></TextInput>
                            </FormField>
                        </Box>
                    </Box>
                    <Box background="brand">
                        <Heading textAlign='center' level="2" fill>Campus Involvement</Heading>
                    </Box>
                    <Box pad={{ bottom: 'small' }}>
                        <FormField fill name="activities" htmlFor="activities-input" required label="Activities" >
                            <TextArea id="activities-input" name="activities" value={applicant?.activities}></TextArea>
                        </FormField>
                    </Box>
                    <Box background="brand">
                        <Heading textAlign='center' level="2" fill>References</Heading>
                    </Box>
                    <Box>
                        <Paragraph textAlign='center' fill>
                            The Morton Ranch High School Athletic Booster Club requires that students applying for this position are recommended by at least 2 Morton Ranch High School staff members.
                        </Paragraph>
                        <Box direction="row-responsive" gap="small" pad="small">
                            <FormField name="references" htmlFor="references-input" required label="References" >
                                <Box fill height={{ min: 'small', max: "medium" }} overflow="auto" width={{ min: 'medium' }}>
                                    <List primaryKey="name" secondaryKey="title" data={applicant?.references} action={this.renderReference} />
                                </Box>
                            </FormField>
                            <Box width="165px" pad={{ top: "40px" }}>
                                <Button primary color="brand" label="Add Reference" onClick={() => { this.setState({ addReference: true }); }} />
                            </Box>
                        </Box>
                    </Box>
                    <Box background="brand">
                        <Heading textAlign='center' level="2" fill>Acknowledgement</Heading>
                    </Box>
                    <Box gap="small" pad="small">
                        <CheckBox checked={applicant?.acknowledged} onChange={(event) => this.onAcknowledged(event.target.checked)}
                            label={<Paragraph fill>
                                I acknowledge that I understand this is a volunteer position which requires up to 4 social media posts per week on all ABC platforms. I will be
                                mindful of posts and not post anything that could compromise the reputation of the school or any associated program. I will adhere to the posting
                                guidelines provided to me upon acceptance.
                            </Paragraph>} />
                    </Box>
                </Box>
                <Box direction='row-responsive' gap="small" pad="small" justify='end'>
                    <Button type="submit" primary color="brand" label="Submit Application" disabled={!this.canSubmit()} />
                    <Button type="reset" secondary color="brand" label="Clear Application" />
                </Box>
            </Form>
        </Box>;
    }
}
