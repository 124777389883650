import React from 'react';
import { Box, Form, FormField, TextInput, Button, Select, Heading, Header, Footer } from 'grommet';
import { Close } from 'grommet-icons';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';
import { AdditionalAthlete as AdditionalAthleteModel } from '../models/AdditionalAthlete';
import { ApplicationState } from '../store';
import { Sports } from '../models/Common';

interface IAdditionalAthleteProps {
    data?: AdditionalAthleteModel | undefined;
    callback: Function;
    onClose?: any;
}

interface AdditionalAthleteState {
    athlete: AdditionalAthleteModel | undefined;
    showSelect: boolean;
}


type AdditionalAthleteProps =
    IAdditionalAthleteProps // ... state we've requested from the Redux store
    & ApplicationState // ... state we've requested from the Redux store
    & RouteComponentProps<any>; // ... plus incoming routing parameters

export default class AdditionalAthlete extends React.PureComponent<AdditionalAthleteProps, AdditionalAthleteState> {
    constructor(props: AdditionalAthleteProps) {
        super(props);

        this.state = {
            athlete: undefined,
            showSelect: true
        };
    }

    onAddAdditionalAthlete = () => {
        this.props.callback(this.state.athlete);
    }

    showSports = (show: boolean) => {
        this.setState({ showSelect: show });
        if (show) {
            setTimeout(() => jQuery("#addl-sports-select").trigger("click"), 250);
        }
    }

    public render() {
        return <Box gap="small" fill pad="small">
            <Header direction="row-responsive" elevation="small" pad="small" justify="between">
                <Heading level="4" fill>Add Athlete</Heading>
                <Box align="end" justify="start">
                    <Button type="button" icon={<Close size="small" />} onClick={this.props.onClose} />
                </Box>
            </Header>
            <Box pad="small" gap="medium" border>
                <Form value={this.state.athlete} onChange={nextValue => this.setState({ athlete: nextValue })} >
                    <FormField name="name" htmlFor="name-input" label="Name" required >
                        <TextInput id="name-input" name="name" ></TextInput>
                    </FormField>
                    <Box direction="row-responsive" gap="medium" fill="horizontal" >
                        <Box width="small">
                            <FormField name="grade" htmlFor="addl-grade-input" required label="Grade" >
                                <TextInput type="number" id="addl-grade-input" name="grade" min={7}></TextInput>
                            </FormField>
                        </Box>
                        <Box width="small">
                            <FormField name="age" htmlFor="addl-age-input" required label="Age" >
                                <TextInput type="number" id="addl-age-input" name="age" min={12}></TextInput>
                            </FormField>
                        </Box>
                        <Box width="small">
                            <FormField name="shirtSize" htmlFor="addl-shirtSize-input" label="Shirt Size" >
                                <Select id="addl-shirtSize-input" options={["XS", "S", "M", "L", "XL", "2XL", "3XL"]} name="shirtSize" dropHeight='small' />
                            </FormField>
                        </Box>
                    </Box>
                    <FormField name="school" htmlFor="addl-school-input" required label="School" >
                        <TextInput id="addl-school-input" name="school" min={12}></TextInput>
                    </FormField>
                    <FormField name="sports" htmlFor="addl-sports-input" label="Sports(s)" required >
                        {!this.state.showSelect && <TextInput value={this.state.athlete?.sports ? this.state.athlete?.sports.join(", ") : undefined}
                            onFocus={() => this.showSports(true)} />}
                        {this.state.showSelect && <Select id="addl-sports-select" options={Sports} multiple name="sports" onClose={() => this.showSports(false)}
                            dropHeight='medium' />}
                    </FormField>
                </Form>
            </Box>
            <Footer direction="row" gap="small" pad="small" justify="end">
                <Button primary label="Save" color="brand" onClick={this.onAddAdditionalAthlete} />
                <Button secondary label="Cancel" onClick={this.props.onClose} color="brand" />
            </Footer>
        </Box>
    }
}
