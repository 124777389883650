import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { EventAttendee } from '../models/EventAttendee';
import { SchoolEvent } from '../models/SchoolEvent';
import { setLoading, setError, setMessage } from './Site';
import { EmptyGuid } from '../models/Common'

let serialize = require('serialize-javascript');

export interface SchoolEventState {
    event: SchoolEvent | undefined;
    attendee: EventAttendee | undefined;
    events: SchoolEvent[];
    attendees: EventAttendee[];
}

let initialState: SchoolEventState = {
    event: undefined,
    events: [],
    attendee: undefined,
    attendees: []
}

export const schoolEventSlice = createSlice({
    name: 'SchoolEvent',
    initialState,
    reducers: {
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setEvents: (state, action) => {
            state.events = action.payload;
        },
        setAttendee: (state, action) => {
            state.attendee = action.payload;
        },
        setAttendees: (state, action) => {
            state.attendees = action.payload;
        }
    }
});

export const { setEvent, setEvents, setAttendee, setAttendees } = schoolEventSlice.actions;

export const retrieveEvent = (event: string): AppThunk => async (dispatch) => {
    let request = {
        name: event
    }

    fetch('api/schoolEvent/retrieveEvent', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setEvent(data));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const retrieveEvents = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    fetch('api/schoolEvent/List', {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data.statusCode || data.message) {
            throw Error(data.message);
        }
        dispatch(setEvents(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const saveEvent = (event: SchoolEvent): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    fetch('api/schoolEvent/saveEvent', {
        method: "POST",
        body: serialize(event),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setEvent(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const retrieveAttendees = (eventId: string): AppThunk => async (dispatch) => {
    let request = {
        id: eventId
    }

    dispatch(setLoading(true));
    fetch('api/schoolEvent/retrieveAttendees', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setAttendees(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const checkInAttendee = (attendee: EventAttendee): AppThunk => async (dispatch) => {
    let request = {
        ...attendee,
    }

    dispatch(setLoading(true));
    fetch('api/schoolEvent/checkInAttendee', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setAttendees(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const registerAttendee = (attendee: EventAttendee, callback: Function): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let request = {
        ...attendee,
        event: state.events.event
    }

    dispatch(setLoading(true));
    fetch('api/schoolEvent/register', {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        if (!data.id || (data.id === EmptyGuid)) {
            setMessage("An error occurred saving registration information. Please send the email that was composed.");
            window.open(`mailto:support@mrhsabc.com&subject=Issue saving Registration information&body=${serialize(request)}%0d%0a%0d%0a${serialize(data)}`);
        }
        else {
            dispatch(setAttendee(data));
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const verifyRegistration = (orderId: any, onSuccess: Function, onFailure: Function): AppThunk => async (dispatch) => {
    let url = 'api/schoolEvent/verifyAttendee';
    let request = {
        orderId: orderId
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: serialize(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(async data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        if (data) {
            dispatch(setAttendee(data));
            onSuccess();
        }
        else {
            onFailure();
        }
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const sendConfirmationEmail = (attendee: EventAttendee): AppThunk => async (dispatch) => {

    dispatch(setLoading(true));
    fetch('api/schoolEvent/sendConfirmationEmail', {
        method: "POST",
        body: serialize(attendee),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode || data?.message) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        setMessage("Confirmation email has been sent.");
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}


export default schoolEventSlice.reducer;
