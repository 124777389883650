import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import {
    Box, Form, Image, Heading, Text, Button, Anchor, FormField, TextInput, RadioButton, List, Layer,
    Table, TableHeader, TableRow, TableBody, TableCell, CheckBox, Select, Header, Footer
} from 'grommet';
import _ from 'lodash';
import { SchoolEvent as SchoolEventModel } from '../models/SchoolEvent';
import { registerAttendee, schoolEventSlice } from '../store/SchoolEvent';
import { parseJSON } from 'date-fns/esm';

const mapDispatchToProps = {
    registerAttendee,
    ...schoolEventSlice
}

interface ISchoolEventProps {
    data: SchoolEventModel | undefined;
    onClose: Function;
    onSuccess: Function;
}

interface SchoolEventState {
    event: SchoolEventModel | undefined;
}

type SchoolEventProps = ISchoolEventProps &
    ApplicationState // ... state we've requested from the Redux store
    // & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

export default class SchoolEvent extends React.PureComponent<ISchoolEventProps, SchoolEventState> {
    constructor(props: SchoolEventProps) {
        super(props);

        this.state = {
            event: {
                ...this.props.data,
                startDate: this.props.data?.startDate ? parseJSON(this.props.data?.startDate) : this.props.data?.startDate,
                endDate: this.props.data?.endDate ? parseJSON(this.props.data?.endDate) : this.props.data?.endDate
            } as SchoolEventModel
        };
    }

    onSubmit = () => {
        let event = { ...this.state.event } as SchoolEventModel;

        event.displayName = event.displayName ?? event.internalName;
        this.props.onSuccess(event);
    }

    onCancel = () => {
        this.props.onClose();
    }

    canSubmit = () => {
        let event = this.state.event;
        let result = event?.internalName && event?.displayName && event?.startDate && event?.endDate && event?.cost;

        return result;
    }

    public render() {
        return <Box pad="small" fill gap="small">
            <Form value={this.state.event} onChange={nextValue => this.setState({ event: nextValue })} >
                <Box border fill pad="small" >
                    <FormField fill name="internalName" htmlFor="internalName-input" required label="Internal Name" >
                        <TextInput id="internalName-input" name="internalName"></TextInput>
                    </FormField>
                    <FormField fill name="displayName" htmlFor="displayName-input" label="Display Name" >
                        <TextInput id="displayName-input" name="displayName"></TextInput>
                    </FormField>
                    <FormField fill name="startDate" htmlFor="startDate-input" required label="Start Date" >
                        <TextInput type="date" id="startDate-input" name="startDate" ></TextInput>
                    </FormField>
                    <FormField fill name="endDate" htmlFor="endDate-input" required label="End Date" >
                        <TextInput type="date" id="endDate-input" name="endDate"></TextInput>
                    </FormField>
                    <FormField fill name="cost" htmlFor="cost-input" required label="Cost" >
                        <TextInput type="number" id="cost-input" name="cost" min={0}></TextInput>
                    </FormField>
                </Box>
                <Box direction='row-responsive' justify='end' gap="small" pad="small">
                    <Button alignSelf='end' type="submit" primary color="brand" label="Save" onClick={this.onSubmit} disabled={!this.canSubmit()} />
                    <Button alignSelf='end' secondary color="brand" label="Cancel" onClick={this.onCancel} />
                </Box>
            </Form >
        </Box >;
    }
};
