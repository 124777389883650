import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { Box, Image } from 'grommet';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface FlyerState {
}

type FlyerProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class Flyer extends React.PureComponent<FlyerProps, FlyerState> {
    constructor(props: FlyerProps) {
        super(props);

        this.props.setLoading(true);
        this.props.setActiveMenu("socialMedia-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="medium">
            <Image fit="contain" src='images/Social Media Recruiting.jpg' />
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Flyer as any);
