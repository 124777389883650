import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { Box, Image, Text, Button, Anchor, Card, CardHeader, CardBody, Tabs, Tab } from 'grommet';
import { Ticket, Plan, Domain, Link, Twitter, Schedule } from 'grommet-icons';
import { siteSlice } from '../../store/Site';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const mapDispatchToProps = {
    ...siteSlice.actions
}

interface IVolleyballProps {
}

interface VolleyballState {
    checked: boolean;
    activeTab: number;
}

type VolleyballProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Volleyball extends React.PureComponent<VolleyballProps, VolleyballState> {
    constructor(props: VolleyballProps) {
        super(props);

        let activeTab = 0;

        this.state = {
            checked: false,
            activeTab: activeTab
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("sports-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
    }

    public render() {
        return <Box align='center' pad={{ top: "medium", bottom: "small" }} fill overflow="auto" gap="small" >
            <Box direction='row-responsive' gap='medium' align='center' justify='center' fill >
                <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "450px" }}>
                    <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small'><Link size='small' /><Text weight={"bold"}>Useful Links</Text></CardHeader>
                    <CardBody gap='small' pad="small">
                        <Anchor icon={<Ticket size='large' />} target='_blank' label={<Text weight='bold'>Buy Game Tickets</Text>}
                            href='https://www.vancoevents.com/us/eventlist/katyisdhsathleticevents?embed=1' />
                        <Anchor icon={<Plan size='large' />} target='_blank' label={<Text weight='bold'>Practice Schedule</Text>}
                            href='https://docs.google.com/document/d/1MknC0zta_Cw-LUEMj17OJrnKIIbiEwRm/edit?rtpof=true&sd=true' />
                        <Anchor icon={<Domain size='large' />} target='_blank' label={<Text weight='bold'>Team Site</Text>}
                            href='https://www.canva.com/design/DAEiT_kvAUI/E1YKyZt6CgpfJJKl9ceTTw/view?utm_content=DAEiT_kvAUI&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton#1' />
                    </CardBody>
                </Card>
                <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "450px" }} >
                    <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small' ><Twitter size='small' /><Text weight={"bold"}>Twitter</Text></CardHeader>
                    <CardBody overflow='auto'>
                        <TwitterTimelineEmbed sourceType="profile" screenName="MortonRanch_VB" options={{ height: '480px' }} />
                    </CardBody>
                </Card>
                <Card height={{ min: '510px', max: '510px' }} width={{ min: '350px', max: "550px" }}>
                    <CardHeader background={{ color: 'brand' }} pad="small" justify='center' dir='row' gap='small'><Schedule size='small' /><Text weight={"bold"}>Schedules</Text></CardHeader>
                    <CardBody gap='small' pad="small">
                        <Tabs alignControls='start' activeIndex={this.state.activeTab} onActive={this.onActivateTab} >
                            <Tab title="Varsity" >
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=5&Tm=11815&L=1&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="Junior Varsity">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=5&Tm=11819&L=2&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="Freshmen A">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=5&Tm=11825&L=3&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                            <Tab title="Freshmen B">
                                <Box pad="small" align='center'>
                                    <Button href='https://www.rankone.com/Schedules/View_Schedule_Web.aspx?P=0&D=E60DB75B-3AB2-4FE7-B746-0735376AFB5B&S=600&Sp=5&Tm=11829&L=3&Mt=0' target='_blank'>
                                        <Image fit='contain' src='images/rankOne-logo-transparent.png' />
                                    </Button>
                                </Box>
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </Box>
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Volleyball as any);
