import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { siteSlice } from '../../store/Site';
import { saveApplicant } from '../../store/SocialMedia';
import { Box, Heading, Layer, Header, Footer, Text, Paragraph, Button } from 'grommet';
import { VolunteerApplicant } from '../../models/VolunteerApplicant';
import SocialMediaVolunteer from '../../components/SocialMediaVolunteer';
import { push } from 'connected-react-router'

const mapDispatchToProps = {
    saveApplicant,
    push,
    ...siteSlice.actions
}

interface VolunteerApplicationState {
    applicant: VolunteerApplicant | undefined;
    showSuccessMsg: boolean;
}

type VolunteerApplicationProps = ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class VolunteerApplication extends React.PureComponent<VolunteerApplicationProps, VolunteerApplicationState> {
    constructor(props: VolunteerApplicationProps) {
        super(props);

        this.state = {
            applicant: undefined,
            showSuccessMsg: false
        };

        this.props.setLoading(true);
        this.props.setActiveMenu("socialMedia-menu")
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onSubmit = (value: VolunteerApplicant) => {
        this.props.saveApplicant(value, this.onSaved);
        this.setState({ applicant: value })
    }

    onCancel = () => {
        this.setState({ applicant: undefined });
    }

    onSaved = () => {
        this.setState({ showSuccessMsg: true });
    }

    onCloseMessage = () => {
        this.setState({ showSuccessMsg: false });
        this.props.push('/socialMedia/Flyer');
    }

    public render() {
        return <Box align='center' overflow="auto" gap="small">
            {this.state.showSuccessMsg && <Layer>
                <Box>
                    <Header background="brand">
                        <Box fill="horizontal"><Text textAlign="center" color="white">Information</Text></Box>
                    </Header>
                    <Box pad="small" alignContent="center">
                        <Paragraph fill textAlign="center">Thank you for your application.  You will be contacted if you are selected.</Paragraph>
                    </Box>
                    <Footer fill="horizontal" pad="small" justify="end">
                        <Box width="xsmall" >
                            <Button primary color="brand" label="OK" onClick={this.onCloseMessage} />
                        </Box>
                    </Footer>
                </Box>
            </Layer>}
            <Heading fill textAlign='center'>Social Media Volunteer Application</Heading>
            <SocialMediaVolunteer onSubmit={this.onSubmit} {...this.props} />
        </Box>;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(VolunteerApplication as any);
